.root {
  position: relative;
  min-height: 3rem;
  max-height: 12rem;
  overflow: hidden;
  margin: 0 0 0.6rem;
}

.overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2rem;
  background: linear-gradient(0deg, #fffc 0%, #fff3 100%);
}
