.window {
  position: fixed;
  top: 15px;
  right: 15px;
  width: auto;
  /* To make it display over Material UI's Dialog */
  z-index: 1400;
}
.container {
  position: relative;
  width: 350px;
  padding: 15px;

  background: #6c55e2;
  box-shadow: 0 0 20.37px 0.63px rgba(2, 6, 14, 0.1);
  border: 1px solid #5644b2;
  border-radius: 3px;

  display: flex;
  align-items: flex-start;
  font-size: 0.9375em;
  color: #fff;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 30px;
  min-height: 30px;
  color: #fff;
}
.closeBtn {
  min-width: 30px;
  min-height: 30px;
  background: 0 0;
  border: none;
  color: #fff;
}
.message {
  flex: 1;

  display: flex;
  align-items: center;
  min-height: 30px;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .container {
    width: 290px;
    padding: 10px;
  }
}
