.root {
    position: relative;
}

.maskUploading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFA;
}

.toolbarBottomLeft {
    position: absolute;
    bottom: 0;
    left: 0;
}

.toolbarBottomRight {
    position: absolute;
    bottom: 0;
    right: 0;
}
