.root {
  font-weight: 700;
}
.title {
  color: #aaa;
  font-size: 0.875rem;
}
.body {
  color: #494949;
  font-size: 1rem;
}
