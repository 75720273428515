.container {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.container .txt {
  font-size: 0.875rem;
  font-weight: 700;
}

.checkbox:checked,
.checkbox:not(:checked) {
  position: absolute;
  left: -9999px;
}
.checkbox + label {
  display: flex;
  margin: 0;
  min-height: 30px;
  align-items: center;
  font-weight: 600;
  font-size: 0.875em;
}
.checkbox:checked + label,
.checkbox:not(:checked) + label {
  width: 100%;
  position: relative;
  padding-left: 30px;
  vertical-align: top;
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0 !important;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #e7e7e7;
  background: #fff;
  border-radius: 3px;
}
.checkbox:checked + label:after,
.checkbox:not(:checked) + label:after {
  content: '\2713';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  font-size: 12px;
  color: #494949;
  transition: all 0.2s;
}
.checkbox:not(:checked) + label:after {
  opacity: 0;
}
.checkbox:checked + label:after {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .container {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .checkbox:checked + label,
  .checkbox:not(:checked) + label {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }

  .container .txt {
    font-size: 0.8rem;
  }

  .checkbox:checked + label,
  .checkbox:not(:checked) + label {
    padding-left: 18px;
  }
}
