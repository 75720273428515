.title {
  position: relative;
  background: #361d83;
}

.content {
  background: #f9f9f9;
}

.actions {
  padding: 24px 10px;
}
