.StripeElement {
    border: 1px solid #dedede;
    border-radius: 3px;
    padding: 5px 20px;
    margin-top: 0;
}

.stripe-name-input {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #dedede;
    font-size: 24px;
    padding: 1px 20px;
    color: #272c2b;
}
.stripe-name-input::placeholder {
    color: #aab7c4;
    font-size: 12px;
}