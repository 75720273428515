.container {
  margin-top: 0.8rem;
  text-align: center;
}

.icon {
  display: block;
  height: 60px;
}
.icon > img {
  opacity: 0.3; /* Make icon dimmer */
  width: 60px;
}

.container [type='checkbox'] {
  display: none;
}

.container .txt {
  font-size: 0.875rem;
  font-weight: 700;
}

.container.stateChecked .icon > img {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .icon {
    height: 40px;
  }
  .icon > img {
    width: 40px;
  }

  .container .txt {
    font-size: 0.8rem;
  }
}
