.shopName {
  margin: 5px 0 0;

  min-height: 3rem;

  font-weight: 600;
  color: black;
  font-weight: 800;
  text-shadow: 0 -5px 5px white, 0 5px 5px white, -5px 0 5px white,
    5px 0 5px white, 0 0 6px white, 0 0 7px white, 0 0 8px white, 0 0 9px white,
    0 0 10px white, 0 0 11px white, 0 0 12px white, 0 0 13px white,
    0 0 14px white, 0 0 15px white, 0 0 16px white, 0 0 17px white,
    0 0 18px white, 0 0 19px white, 0 0 20px white;
}

.avatar {
  width: 80px;
}

.labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.label {
  width: 120px;

  display: flex;
  justify-content: center;
  margin: 5px auto 0;
  padding: 3px 20px;
  background: #361d83;
  border-radius: 5px;

  color: #fff;
  font-size: 0.6875em;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-weight: 500;
}

.onlineRegLabel {
  font-size: small;
}
.onlineReg {
  background: #361d83;
  padding: 5px 10px;
  color: #fff;
  font-size: 9px;
  border-radius: 3px;
  margin-left: 10px;
}
.onlineReg.off {
  background: #aaa;
}

.status {
  position: absolute;
  top: 15px;
  right: 15px;
}

@media screen and (max-width: 992px) {
  .label {
    margin: 1px;
  }
}

@media screen and (max-width: 767px) {
  .shopName {
    position: absolute;
    top: 145px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    line-height: 1.3;
    font-size: 17px;
    font-weight: 700;
    margin: 0;
    padding: 2px 10px;
    min-height: auto;
  }
  .labels {
    position: absolute;
    right: 5px;
    top: 5px;
    left: 5px;
    height: 140px;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  .status {
    position: static;
    top: unset;
    right: unset;
  }
}
