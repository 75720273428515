.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #494949;
  font-size: 15px;
  font-weight: 900;
}
.root i {
  color: #361d83;
}
