.root {
  position: relative;
  margin-bottom: 45px;
}

.root .slide {
  /* desktop */
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 26.04%; /* aspect ratio: 1920:500 */
}

.root .bannerImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
  border-radius: inherit; /* Let container defines its border-radius */
}

/*
.root .bannerImg {
    object-fit: cover;
    height: 500px;
    cursor: pointer;
    width: 100% !important;
}
*/

.root .dotList {
  bottom: -25px;
}
.root .dotList li {
  padding: 0;
  width: 12px;
  height: 12px;
}
.root .dotList li button {
  padding: 0;
  position: relative;
  width: 12px;
  height: 12px;
}
.root .dotList li button:before {
  font-size: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000;
}

/* tablet - aspect ratio: 2:1 */
/*
@media screen and (max-width: 1199px) {
    .root .slide {
        padding-bottom: 50%;
    }
}
*/

@media screen and (max-width: 767px) {
  /* mobile phone */
  .root .slide {
    padding-bottom: 75%; /* aspect ratio: 4:3 */
  }
  .root .dotList li button,
  .root .dotList li button:before {
    width: 10px;
    height: 10px;
  }
}
