.root {
  position: relative;
}
.avatar {
  width: 120px;
  margin: 0 auto;
}
.root > a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px 15px;
  background-color: #fff;
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.13);
  border: 1px solid #ececec;
  text-align: center;
}
.root h2 {
  margin-top: 15px;
  font-weight: 500;
  font-size: 1.3125em;
  color: #666;
}
.root p {
  color: #666;
  font-size: 0.9375em;
}
.root > a:hover {
  background-color: #361d83;
  transition: all 0.3s ease-in;
}
.root > a:hover h2,
.root > a:hover p {
  color: #fff;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 1200px) {
  .root > a {
    padding: 15px 15px 30px;
  }
  .root .avatar {
    width: 90px;
  }
}
@media screen and (max-width: 992px) {
  .root > a {
    margin-top: 15px;
    padding: 15px 10px 30px;
  }
}
@media screen and (max-width: 767px) {
  .root h2 {
    font-size: 1.0625em;
  }
  .root p {
    font-size: 0.8125em;
  }
}
