.root {
  position: relative;
}

.mobileHandle {
  border-radius: 10px !important;

  padding: 0.25rem 0.4rem !important;

  font-size: 1.2em !important;
  font-weight: normal !important;

  min-width: 90px;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  border-bottom: solid 1px #aaa;
  margin-bottom: 6px;
}

.tab {
  display: block;
  margin-left: 1px;
  margin-right: 1px;
  padding: 8px 24px;
  background: #e6e6fa;
  color: #555;
  font-size: 1rem;
  font-weight: 700;
}
.tab:hover {
  color: #000;
}
.tab.active {
  background: #361d83;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .isLong .tabs {
    display: none;
  }
  .isLong.stateOpened .tabs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .isLong.stateOpened .tab {
    border-bottom: solid 1px #aaa;
    padding: 8px 12px;
  }

  .mobileHandle {
    display: block;
    background: 0 0;
    font-weight: 700;
    letter-spacing: 0.05em;
    cursor: pointer;
    padding: 0;
    border: none;
  }
}
