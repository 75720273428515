.root {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #ececec;
  padding-top: 15px;
  padding-bottom: 15px;
}

.mobileHandle {
  display: none;
}

.tabs {
  display: flex;
  align-items: center;
}

.tab {
  display: block;
  position: relative;
  min-width: 60px;
  padding: 1rem 0.25rem;
  font-weight: 900;
  font-size: 0.875em;
  color: #a0a0a0;
  text-align: center;
}
.tab.active,
.tab.active:hover {
  color: #494949;
}
.tab:hover {
  color: #a0a0a0;
}

.tab + .tab {
  margin-left: 15px;
}

/* The artificial underline */
.tab:after {
  position: absolute;
  display: block;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #361d83;
  opacity: 0;
}
.tab.active:after,
.tab:hover:after {
  opacity: 1;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 991px) {
  .mobileHandle {
    display: block;

    border-radius: 10px !important;

    padding: 0.25rem 0.4rem !important;

    font-size: 1.2em !important;
    font-weight: normal !important;

    min-width: 90px;
  }

  .tabs {
    display: none;
  }
  .tab:after {
    display: none;
  }
  .stateOpened .tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    margin-top: 15px;
    width: 100%;
    min-height: auto;
    padding: 15px 20px;
    background: #fff;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
    z-index: 99;
  }

  .tab {
    display: block;
    width: 100%;

    padding: 6px 0;

    font-size: 1em;
    text-align: center;
  }
  .tab.active {
    color: #361d83;
  }
  .tab:hover,
  .tab.active:hover {
    background-color: #eee;
    color: #494949;
  }
  .tab:after {
    bottom: -3px;
  }
  .tab + .tab {
    margin-left: 0;
  }
}
