.sidePanel {
  width: 330px;
  background: #fff;
  box-shadow: 0 4px 42.14px 0.86px rgba(0, 0, 0, 0.09);
}

.siteLogoWrap {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.siteLogo {
  display: block;
  width: 175px;
  height: 125px;
  margin: 0;
  background: url(../../../dist/img/logo.png) center no-repeat;
  background-size: contain;
}

.shopBadge {
  background: #fbfbfc;
  padding: 30px 0;
}

.shopAvatarContainer {
  width: 120px;
}
.shopName {
  margin-top: 10px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125em;
}

.shopNavHandle {
  display: none;
}

.shopNav {
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 100px;
  padding-right: 20px;
}

.navItem + .navItem {
  margin-top: 10px;
}

.navLink {
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  padding: 6px 10px;
  border-radius: 3px;
  color: #111;
  font-size: 1em;
  font-weight: 700;
}

.navLink.navLinkActive {
  background: #361d83;
  color: #fff;
  transition: all 0.3s ease;
}
.navLink.navLinkActive:hover {
  color: #fff;
}
.navLink:hover {
  color: #111;
}

.navIconContainer {
  min-width: 30px;
  text-align: center;
}

.navLinkText {
  padding-left: 20px;
}

@media screen and (max-width: 1199px) {
  .sidePanel {
    width: 250px;
  }
  .shopNav {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 991px) {
  .sidePanel {
    width: 100%;
    position: relative;
  }

  .siteLogoWrap {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
  .siteLogo {
    width: 125px;
    height: 50px;
  }

  .shopNavHandle {
    display: block;
    left: 15px;
    font-size: 1.5em;
    background: 0 0;
    border: none;
    cursor: pointer;
  }
  .shopNavContainer {
    display: none;
  }
  .shopNavContainer.shopNavContainerOpened {
    display: block;
  }

  .shopNav {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .shopNav {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0;
  }
  .navItem + .navItem {
    margin-top: 3px;
  }
  .navLink {
    height: 36px;
  }
}

@media print {
  .sidePanel {
    display: none !important;
  }
}
