/**
 * The rules below help explicitly state the dimension of the ad area, for Google Ads to
 * evaluate available space to render ad.
 *
 * @see also `src/Functions/advertisementHelpers.js`
 *
 * @link https://support.google.com/adsense/answer/9183363?visit_id=637657257101829882-1159730071&rd=1&hl=en
 */

.adContainer.landscape {
  width: 728px;
  height: 90px;
}
.adContainer.portrait {
  width: 160px;
  height: 600px;
}

@media screen and (max-width: 991px) {
  .adContainer.landscape,
  .adContainer.portrait {
    width: 690px;
    height: 90px;
  }
}

@media screen and (max-width: 767px) {
  .adContainer.landscape,
  .adContainer.portrait {
    width: 320px;
    height: 100px;
  }
}
