.scheduleField {
  width: calc(100% - 70px);
}

@media screen and (max-width: 1700px) {
  .scheduleField {
    width: calc(100% - 50px);
  }
}

@media screen and (max-width: 992px) {
  .scheduleField {
    width: 100%;
  }
}
