.root {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* default aspect ratio is 1:1 */
    background-color: #d3d3d3;
}
.root:hover {
    background-color: #7a7a7a;
    transition: all 0.3s ease;
}
.root > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
    border-radius: inherit; /* Let root defines its border-radius */
}

.imgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* https://stackoverflow.com/a/31458204 */
@keyframes flickerAnimation {
    from {
        opacity: 0;
    }
}

.imageBtnPlay {
    width: 60px;
    height: 60px;
    max-width: 60%;
    max-height: 60%;
    animation: flickerAnimation 1s infinite alternate;
}

.imageBtnPlay img {
    width: 100%;
    height: 100%;
}
