.root {
  position: relative;
}

.navHandle {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 96%;
  margin: auto;
  padding: 9px 9px;

  border: none;
  border-radius: 10px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  background-color: #361d83;

  color: #fff;
  font-size: 1.2em;
}

.tabAnchors {
  display: none;
}

.tabAnchor {
  display: block;
  width: 100%;

  padding: 6px 0;

  font-weight: 900;
  font-size: 1em;
  color: #a0a0a0;
  text-align:center;
}

.tabAnchor.active {
  color: #361d83;
}
.tabAnchor:hover,
.tabAnchor.active:hover {
  background-color: #EEE;
  color: #494949;
}

.stateOpened .tabAnchors {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  margin-top: 15px;
  width: 100%;
  min-height: auto;
  padding: 15px 20px;
  background: #fff;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
}
