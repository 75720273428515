.header {
  position: relative;
  background-color: #fff;
  padding: 24px 0;
}

.container {
  position: relative;
}

.themeLogo {
  display: block;
  width: 120px;
  height: 50px;
  background: url(../../../../../dist/img/logo.png) center no-repeat;
  background-size: contain;
}

.signInBtn {
  border: none;
  background: 0 0;
  color: #6d6d6d;
  font-size: 15px;
}
.signInBtn:focus,
.signInBtn:hover {
    color: #6d6d6d;
}

.navbarToolbar {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  width: 360px;
  background: #fff;
  padding: 8px;
  border: 2px solid #ececec;
  box-shadow: 2px 2px 10px 1px #00000066;
  z-index: 199;
}

@media screen and (max-width: 992px) {
  .header {
    border-bottom: 2px solid #ececec;
    padding: 10px 0;
  }
}
@media screen and (max-width: 767px) {
  .container {
    position: static;
  }

  .header .themeLogo {
    width: 100px;
    height: 45px;
  }

  .navbarToolbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-top: 2px solid #ececec;
    border-bottom: 2px solid #ececec;
    box-shadow: 2px 2px 5px 1px #00000066;
    z-index: 199;
  }
}
