
.status {
  position: absolute;
  top: 15px;
  right: 15px;
}

.avatar {
  width: 80px;
}

.shopName {
  min-height: 3rem;
  margin: 5px 0 0;
  min-height: 3rem;
  font-weight: 600;
  color: #666;
}

.schedule {
    color: #6b6b6b;
    font-size: 0.75em;
    font-weight: 700;
}

.onlineRegLabel {
  font-size: small;
}
.onlineReg {
  background: #361d83;
  padding: 5px 10px;
  color: #fff;
  font-size: 9px;
  border-radius: 3px;
  margin-left: 10px;
}
.onlineReg.off {
  background: #aaa;
}

@media screen and (max-width: 767px) {
  .shopName {
    line-height: 1.3;
    font-size: 17px;
    font-weight: 700;
    margin: 10px 0 0;
    padding: 2px 10px;
    min-height: auto;
  }
}
