/**
 * The rules below help explicitly state the dimension of the ad area, for the app to
 * evaluate available space to render ad.
 *
 * @see also `src/Functions/advertisementHelpers.js`
 */

.adContainer a {
  display: block;
  width: 100%;
  height: 100%;
}
.adContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adContainer.landscape {
  width: 728px;
  height: 90px;
}
.adContainer.portrait {
  width: 160px;
  height: 600px;
}

@media screen and (max-width: 991px) {
  .adContainer.landscape,
  .adContainer.portrait {
    width: 690px;
    height: 90px;
  }
}

@media screen and (max-width: 767px) {
  .adContainer.landscape,
  .adContainer.portrait {
    width: 320px;
    height: 100px;
  }
}
