.root {
  padding: 90px 0;
  font-size: 14px;
  font-weight: 500;
}

.socialAnchor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin: 4px;
  border-radius: 50%;
  border: 1px solid #dedede;
}

.termLink {
  text-transform: uppercase;
}

/*
.subscribe {
  display: flex;
  justify-content: space-between;
}
.subscribeField {
  width: calc(100% - 50px);
  border: none;
}
.subscribeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #361d83;
  border: none;
  color: #fff;
}
*/

@media screen and (max-width: 991px) {
  .root {
    padding: 60px 0;
  }
}

