@charset "UTF-8";
.adminTable td {
    vertical-align: middle;
}
.adminWrapper {
    background: #f3f3f3;
}
.adminBox {
    display: flex;
}
.field-ico--right {
    right: 10px;
    left: unset;
}
.field-ico--full-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 2px;
    width: 44px;
    right: 0;
    background: #361d83;
    color: #fff;
    left: unset;
}
.mainPanel {
    width: calc(100% - 330px);
    padding: 60px;
}
.categoryTitle {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1.875em;
}
.categoryTitle > i {
    color: #361d83;
    padding: 0 20px;
}
.tableWrap {
    border: 1px solid #f5f5f5;
    overflow: auto;
}
.tableWrap::-webkit-scrollbar {
    width: 6px;
}
.tableWrap::-webkit-scrollbar:horizontal {
    height: 8px;
}
.tableWrap::-webkit-scrollbar-thumb {
    background: #adadad;
    border-radius: 3px;
}
.tableWrap--auto-height {
    max-height: none;
    height: auto;
}
.adminTable {
    box-shadow: 0 0 8.73px 0.27px rgba(0, 0, 0, 0.2);
    width: 100%;
    background: #fff;
}
.adminTable > thead td {
    padding: 10px 5px;
    text-align: center;
}
.adminTable > thead td:first-child {
    padding-left: 10px;
}
.adminTable > thead td:last-child {
    padding-right: 10px;
}
.adminTable > tbody tr:nth-child(odd) {
    background: #f5f5f5;
}
.adminTable > tbody tr:nth-child(even) {
    background: #fff;
}
.adminTable > tbody td {
    height: 100%;
    padding: 20px 10px;
    border-right: 1px solid #e1e1e1;
    text-align: left;
}
.adminTable > tbody td:last-child {
    border-right: none;
}
.td-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    padding: 8px;
    text-transform: uppercase;
    font-size: 0.875em;
    font-weight: 900;
    color: #fff;
    font-family: Roboto, sans-serif;
    background-color: #494949;
}
.checkbox + label > span {
    position: absolute;
}
.checkbox + label {
    color: #121212;
    display: flex;
    align-items: center;
    min-height: 40px;
    font-weight: 600;
    font-size: 0.875em;
}
.checkbox + label > span {
    transform: translateY(-50%);
}
.checkbox:checked,
.checkbox:not(:checked) {
    position: absolute;
    left: -9999px;
}
.checkbox:checked + label,
.checkbox:not(:checked) + label {
    width: 100%;
    min-height: 40px;
    position: relative;
    padding-left: 30px !important;
    vertical-align: top;
    cursor: pointer;
}
.checkbox:checked + label > span,
.checkbox:not(:checked) + label > span {
    width: calc(100% - 30px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.checkbox--type2:checked + label > span,
.checkbox--type2:not(:checked) + label > span {
    text-transform: uppercase;
    font-weight: 700;
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0 !important;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 2px solid #e7e7e7;
    background: #f5f5f5;
    border-radius: 3px;
}
.checkbox:checked + label:after,
.checkbox:not(:checked) + label:after {
    content: "\2713";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    font-size: 12px;
    color: #494949;
    transition: all 0.2s;
}
.checkbox:not(:checked) + label:after {
    opacity: 0;
}
.checkbox:checked + label:after {
    opacity: 1;
}
.checkbox:checked + label:before {
    border: 2px solid #494949;
}
.head-title {
    text-transform: uppercase;
}
.radio {
    vertical-align: top;
    width: 17px;
    height: 17px;
    margin: 0 3px 0 0;
}
.radio + label {
    cursor: pointer;
    font-size: 1em;
}
.radio:not(checked) {
    position: absolute;
    opacity: 0;
}
.radio:not(checked) + label {
    position: relative;
    padding: 0 0 0 25px;
}
.radio:not(checked) + label:after,
.radio:not(checked) + label:before {
    position: absolute;
    top: 50%;
    content: "";
}
.radio:not(checked) + label:before {
    transform: translateY(-50%);
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #484848;
    border-radius: 50%;
    background: #fff;
}
.radio--no-border:not(checked) + label:before {
    border: none !important;
}
.radio:not(checked) + label:after {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #361d83;
    opacity: 0;
    transition: all 0.2s;
}
.radio:checked + label:after {
    opacity: 1;
}
.table-field {
    position: relative;
    width: 80%;
    margin: 0 auto;
}
.calendar-control__item + .calendar-control__item {
    margin-left: 15px;
}
.pagination-box {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 15px;
    background: #fff;
    box-shadow: none;
    border: none !important;
}
.main-container__content .pagination-box {
    justify-content: flex-end;
}
.pagination > li > a {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: 0 0;
    border: none;
    border-right: 2px solid #f4f4f4;
    text-align: center;
}
.pagination__item {
    display: block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: 0 0;
    border: none;
    border-right: 2px solid #f4f4f4;
    cursor: pointer;
}
.pagination__item:disabled {
    opacity: 0.6;
    cursor: default;
}
.pagination__item.active {
    background: #361d83;
    color: #fff;
}
.month-select {
    width: 320px;
    position: relative;
    padding: 0 20px;
}
.month-select__box {
    width: 290px;
    text-align: center;
}
.month-select__btn {
    position: absolute;
    background: 0 0;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.calendar-table tbody tr,
.calendar-table thead tr,
.calendar-table--day thead tr {
    border-bottom: 1px solid #ededed;
}
.calendar-table tbody tr td:first-child,
.calendar-table--day thead tr td:first-child {
    border-left: 1px solid #ededed;
}
.month-select__btn--left {
    left: -10px;
}
.month-select__btn--right {
    right: -10px;
}
.month-select__item {
    font-size: 2.625em;
    font-weight: 300;
}
.calendar-table {
    width: 100%;
    table-layout: fixed;
}
.calendar-table thead tr td {
    text-align: left;
    padding-left: 10px;
}
.calendar-table tbody tr td {
    vertical-align: top;
    text-align: left;
    padding: 10px 0 0;
    border-right: 1px solid #ededed;
}
.calendar-table--week .head-title {
    display: block;
    padding-bottom: 15px;
    font-size: 1.125em;
}
.calendar-table--week tbody tr td {
    padding: 0;
    vertical-align: middle;
}
.calendar-table--day .head-title {
    display: block;
    text-align: center;
    font-size: 1.125em;
}
.calendar-table--day thead tr {
    border-top: 1px solid #ededed;
}
.calendar-table--day thead tr td {
    vertical-align: top;
    text-align: left;
    padding: 0;
    border-right: 1px solid #ededed;
}
.calendar-table--day tbody tr td {
    padding: 0;
}
.calendar-time {
    font-weight: 600;
    color: #494949;
    font-size: 1.125em;
    text-align: center;
}
.calendar-number {
    padding: 10px;
    background: 0 0;
    font-weight: 400;
    border: none;
    cursor: pointer;
}
.number-td--disable {
    background-color: #ededed;
}
.number-td--disable .calendar-number {
    color: #a8a8a8;
}
.calendar-inner {
    display: flex;
    flex-direction: column;
    min-height: 90px;
}
.calendar-inner--week {
    min-height: 50px;
    justify-content: center;
}
.calendar-inner--day {
    min-height: 30px;
    justify-content: center;
}
.head-title {
    font-size: 1.3125em;
    font-weight: 700;
}
.month {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.month__item {
    background: 0 0;
    border: none;
    color: #646464;
    font-size: 0.9375em;
    cursor: pointer;
}
.month__item.active {
    font-size: 1.125em;
    font-weight: 900;
}
.main-container {
    background: #fff;
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 10px 51.33px 7.67px rgba(0, 0, 0, 0.02);
    border: 1px solid #f5f5f5;
}
.page-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #ececec;
}
.page-title {
    font-size: 2em;
    font-weight: 300;
}
.calendar-event p {
    font-weight: 700;
}
.calendar-event {
    position: relative;
    margin-top: auto;
    width: 100%;
    padding: 5px;
    font-size: 0.8125em;
    background: #f6f7fa;
    border-left: 4px solid #1b5bed;
    cursor: pointer;
}
.calendar-event span {
    font-weight: 500;
}
.calendar-event + .calendar-event {
    margin-top: 3px;
}
.calendar-event--type2 {
    border-left: 4px solid #8dc63f;
}
.calendar-event--type3 {
    border-left: 4px solid #ec008c;
}
.calendar-event--type4 {
    border-left: 4px solid #ff0;
}
.calendar-event--day {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
}
.calendar-event--day .event {
    right: 0;
}
.calendar-event--note {
    display: flex;
    align-items: center;
    min-height: 30px;
}
.calendar-event--note > p {
    padding-right: 15px;
}
.number-td:nth-child(5) .event,
.number-td:nth-child(6) .event,
.number-td:nth-child(7) .event,
.number-td:nth-child(8) .event {
    right: unset;
    left: -365px;
}
.event {
    position: absolute;
    top: 50px;
    display: none;
    width: 350px;
    background: #fff;
    border: 1px solid #ececec;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2);
    z-index: 99;
}
.event.active {
    display: block;
    z-index: 99;
}
.event__close {
    position: absolute;
    right: 15px;
    top: 10px;
    background: 0 0;
    border: none;
    cursor: pointer;
}
.calendar-wrap {
    position: relative;
}
.event--bottom {
    top: 50px;
}
.event--top {
    top: unset;
    bottom: calc(100% + 50px);
}
.calendar-wrap--week {
    margin-top: 15px;
}
.calendar-wrap--day {
    margin-top: 25px;
}
.calendar-control__item {
    background: #361d83;
    color: #fff;
}
.calendar-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table-select {
    display: flex;
    align-items: center;
}
.calendar-control {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;
}
.calendar-control__item {
    display: block;
    height: 50px;
    width: 120px;
    border-radius: 3px;
    line-height: 50px;
    text-align: center;
    font-size: 0.9375em;
    font-weight: 700;
    border: none;
}
.calendar-control__item--export {
    background: #8dc63f;
}
.invite-box {
    background: url(../img/invite.png) center no-repeat;
    background-size: cover;
}
.invite__btn {
    display: block;
    width: 160px;
    height: 50px;
    background: #ff7800;
    border: none;
    border-radius: 3px;
    font-size: 0.9375em;
    font-weight: 900;
    color: #fff;
}
.invite__field {
    position: relative;
    width: calc(100% - 160px);
}
.invite__item {
    width: 100%;
    height: 50px;
}
.switch-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
}
.switch-checkbox + label {
    position: absolute;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
    min-height: 50px;
    padding: 0 0 0 60px;
    cursor: pointer;
}
.switch-checkbox + label > span {
    top: 50%;
}
.switch-checkbox + label .admin-purchaser,
.switch-checkbox + label .stuff-purchaser {
    color: #272c2b;
}
.switch-checkbox + label > span {
    position: absolute;
    transform: translateY(-50%);
    font-weight: 900;
    font-size: 0.9375em;
}
.switch-checkbox + label:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 26px;
    border-radius: 13px;
    background: #9fd468;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}
.switch-checkbox + label:after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(50% - 10px);
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
}
.switch-checkbox:checked + label:before {
    background: #b6b6b6;
}
.switch-checkbox:checked + label:after {
    left: calc(50% + 10px);
}
.switch-box {
    width: 100%;
    min-width: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.first-opt {
    left: 0;
}
.second-opt {
    left: calc(50% + 40px);
}
.service-price {
    display: flex;
    align-items: center;
}
.service-price__check,
.service-price__field {
    width: 50%;
}
.service-price__field--range {
    width: 120px;
}
.service-price__help {
    width: 10%;
    text-align: center;
}
.service-price + .service-price {
    margin-top: 15px;
}
.album-head h2,
.upload-box__title {
    font-weight: 900;
    text-transform: uppercase;
}
.album-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}
.album-head h2 {
    color: #361d83;
}
.upload-box {
    position: relative;
    background: #fafafa;
}
.upload-box__title {
    padding: 15px 0;
    color: #666;
}
.upload-gallery {
    display: flex;
    margin: 15px -15px 0;
    flex-wrap: wrap;
}
.gallery-col {
    margin-top: 15px;
    width: 20%;
    padding: 0 15px;
}
.gallery-item {
    position: relative;
    width: 100%;
    height: 170px;
    background-color: #e2e2e0;
}
.gallery-item > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gallery-item--full-width {
    width: 100% !important;
}
.gallery-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #c1c1bf;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    cursor: pointer;
    background: 0 0;
    border: none;
}
.content-field__item {
    height: 250px;
}
.mes-triangle {
    position: absolute;
    left: -16px;
    top: 16px;
    display: inline-block;
    color: #361d83;
    text-shadow: 0 0 0 rgba(255, 255, 255, 0.7), 0 -1px -1px rgba(221, 221, 221, 0.9);
    font-size: 18px;
    transform: rotate(-90deg);
}
.mes-triangle--owner {
    transform: rotate(90deg);
    top: 15px;
    left: unset;
    right: -16px;
    text-shadow: 0 0 0 rgba(255, 255, 255, 0.7), 0 -1px 1px rgba(221, 221, 221, 0.9);
    color: #fff;
}
.enter-message {
    margin-top: 30px;
    position: relative;
}
.enter-message__field {
    width: 100%;
    border: none;
    background: #f6f6f6;
    height: 50px;
    padding: 0 45px 0 20px;
}
.enter-message__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: 0 0;
    border: none;
    right: 15px;
    color: #494949;
    font-size: 1.5625em;
}
.shop-avatar {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: #e2e2e0;
    border: 1px solid transparent;
}
.shop-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.shop-avatar__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: 0 0;
    border: none;
    color: #a8a8a7;
    font-size: 1.5625em;
    cursor: pointer;
}
.shop-avatar .remove-img {
    bottom: -50px;
    right: 50%;
    transform: translateX(50%);
}
.select-all {
    width: 100px;
    padding: 0 10px;
    border: 1px solid #ebebeb;
}
.price-item__img {
    position: relative;
    height: 180px;
}
.price-item__img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.price-sicker {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    z-index: 2;
    background: #f6f6f6;
    color: #666;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 15px;
}
.price-sicker--type2 {
    position: relative;
    margin: 0 auto;
    left: unset;
    top: unset;
    transform: none;
    width: 150px;
    border-radius: 4px;
    font-size: 0.875em;
    font-weight: 500;
}
.date-drop {
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 230px;
    z-index: 10;
}
.date-drop__item {
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    background-color: #fff;
}
.date-drop__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.date-drop__footer {
    padding: 15px 10px;
    border-top: 1px solid #c6c6c6;
}
.date-select {
    position: relative;
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    height: 38px;
    width: 80px;
    z-index: 1;
}
.date-select__item {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border: none;
    color: #666;
    background: 0 0;
}
.date-select__ico {
    position: absolute;
    right: 5px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    border-left: 1px solid #d3d3d3;
    z-index: -1;
}
.date-check {
    display: flex;
    align-items: center;
    width: 50px;
    margin: 0 10px;
}
.checkbox-date {
    vertical-align: top;
    width: 17px;
    height: 17px;
}
.checkbox-date + label {
    cursor: pointer;
}
.checkbox-date:not(checked) {
    position: absolute;
    opacity: 0;
}
.checkbox-date:not(checked) + label {
    position: absolute;
}
.checkbox-date:not(checked) + label:before {
    content: "";
    position: absolute;
    top: -13px;
    left: 0;
    width: 50px;
    height: 26px;
    border-radius: 13px;
    border: 1px solid #c6c6c6;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07);
}
.checkbox-date:not(checked) + label:after {
    content: "\2022";
    color: #1875f0;
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    top: -10px;
    left: 2px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #c6c6c6;
    transition: all 0.2s;
    font-size: 22px;
    line-height: 14px;
}
.checkbox-date:checked + label:before {
    background: #fff;
}
.checkbox-date:checked + label:after {
    left: 28px;
}
.date-choise {
    display: flex;
    align-items: center;
    justify-content: center;
}
.schedule-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.review-answer__title {
    font-weight: 700;
    text-transform: uppercase;
}
.product-photo {
    width: 19%;
    margin-right: calc(5% / 4);
    margin-top: 15px;
}
.product-photo:nth-child(5n) {
    margin-right: 0;
}
.product-name-field {
    margin: 10px 0;
}
.checkout {
    padding: 30px;
    border: 1px solid #ececec;
    background: #f5f5f5;
}
.message--owner {
    justify-content: flex-end;
}
.checkbox--rights-type:checked + label > span,
.checkbox--rights-type:not(:checked) + label > span {
    width: auto;
    position: static;
    transform: none;
}
.price__descr {
    display: block;
    margin: 15px auto 0;
    resize: none;
    height: 100px;
    width: calc(100% - 30px);
    padding: 10px 15px;
}
.error-text--top {
    top: auto;
    bottom: calc(100% + 10px);
}
.error-text--top:after {
    top: 100% !important;
    transform: rotate(180deg) !important;
}
.error-text--to-left {
    right: unset !important;
    left: 0;
}
.error-text--to-left:after {
    right: unset !important;
    left: 9px;
}
.error-field {
    position: relative;
}
.error-field .form-item {
    border-color: #ed1c24;
}
.error-field .checkbox:checked + label:before,
.error-field .checkbox:not(:checked) + label:before {
    border-color: #ed1c24 !important;
}
.error-field .shop-avatar__btn {
    color: #ed1c24;
}
.shop-avatar.error-field {
    border-color: #ed1c24;
}
.error-ico {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ef3a41;
    font-size: 14px;
}
.error-ico.error-ico--left {
    right: auto;
    left: 5px;
}
.remove-img {
    position: absolute;
    right: 5px;
    bottom: 5px;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #494949;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
}
.remove-img:hover {
    color: #fff;
}
.service-type {
    display: flex;
    align-items: center;
}
.service-type__field {
    width: 200px;
}
.service-type__field .form-item {
    background-color: #fff;
    font-size: 14px;
}
.service-type__btn {
    display: block;
    margin-left: 10px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border: none;
    background: #361d83;
    color: #fff;
    cursor: pointer;
}
.calendar-tr {
    display: flex;
}
.calendar-td {
    width: calc(100% / 7);
}
.calendar-table__head .calendar-td {
    text-align: left;
    padding-left: 10px;
}
.calendar-table__body .calendar-tr,
.calendar-table__head .calendar-tr {
    border-bottom: 1px solid #ededed;
}
.calendar-table__body .calendar-tr .calendar-td:first-child {
    border-left: 1px solid #ededed;
}
.number-td {
    vertical-align: top;
    text-align: left;
    border-right: 1px solid #ededed;
}
.number-td.disabled {
    height: 100%;
    min-height: 100%;
    background: #bbbdc0;
}
.number-td.empty {
    height: 100%;
    min-height: 100%;
    background: #eff2f8;
}
.number-td--fixed {
    width: 150px;
}
.number-td--day {
    width: 100%;
}
.day-table table {
    width: 100%;
    table-layout: fixed;
}
.page-add {
    margin-left: auto;
}
.prom-filter--date .react-daterange-picker__wrapper {
    width: 100%;
    height: 44px;
    line-height: 44px;
    border-radius: 3px;
    padding: 0 10px;
    border: 2px solid #eaeaea !important;
    font-size: 14px;
}
.prom-filter--date .react-daterange-picker__button,
.prom-filter--date .react-daterange-picker__button:enabled {
    padding: 0 5px;
}
.review-answer {
    margin-top: 15px;
}
.review-answer {
    border-top: 2px solid #ececec;
    padding-top: 15px;
}
.review-answer__title {
    font-size: 0.875em;
    padding: 5px 0;
}
.review-answer__text {
    font-size: 0.8125em;
    font-weight: 400;
    line-height: 2;
}
.answer__img {
    width: 60px;
    margin-bottom: 5px;
}
.answer__name {
    width: calc(100% - 60px);
    font-weight: 700;
    padding-left: 15px;
    font-size: 0.875em;
}
.page-add {
    display: flex;
    justify-content: flex-end;
}
.adminTable > tbody td {
    word-break: break-all;
}
@media screen and (max-width: 1700px) {
    .main-container {
        padding: 30px 15px;
    }
}
@media screen and (max-width: 1600px) {
    .page-head {
        padding-top: 15px;
    }
    .mainPanel {
        padding: 30px 15px;
    }
    .calendar-head__hints {
        margin-top: 10px;
    }
    .month-select {
        width: 230px;
        margin-left: 0;
    }
    .month-select__item {
        font-size: 1.75em;
    }
    .month-select__box {
        width: 100%;
    }
    .history-calendar {
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
    }
}
@media screen and (max-width: 1440px) {
    .calendar-head {
        flex-direction: column;
        align-items: flex-start;
    }
    .calendar-head--type2 {
        flex-direction: row;
        align-items: center;
    }
    .product-photo {
        width: 24%;
        margin-right: calc(4% / 4);
    }
    .product-photo:nth-child(5n) {
        margin-right: calc(4% / 4);
    }
    .product-photo:nth-child(4n) {
        margin-right: 0;
    }
}
@media screen and (max-width: 1200px) {
    .number-td--fixed {
        width: 100px;
    }
    .main-container {
        padding-top: 15px;
    }
    .gallery-col {
        width: 33.3%;
    }
    .calendar-head {
        flex-direction: column;
        align-items: flex-start;
    }
    .mainPanel {
        width: calc(100% - 250px);
    }
    .gallery-item {
        margin-top: 15px;
    }
    .upload-gallery {
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 992px) {
    .pagination > li > a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-right-width: 1px;
    }
    .pagination > li,
    .pagination > li:last-child > a {
        border-right: none;
    }
    .pagination > li {
        width: auto;
        height: auto;
        line-height: 1;
    }
    .news-prev-img {
        margin-bottom: 10px;
    }
    .month-select {
        display: flex;
        align-items: center;
    }
    .month-select__btn--left {
        left: 0;
    }
    .page-title {
        font-size: 1.75em;
        line-height: 1.2;
    }
    .categoryTitle {
        font-size: 1.3125em;
    }
    .service-price {
        flex-wrap: wrap;
    }
    .service-price__check {
        width: 100%;
    }
    .service-type {
        align-items: center;
    }
    .service-type__field {
        width: calc(100% - 48px);
    }
    .service-price__field {
        width: 100%;
    }
    .service-price__field--range {
        width: 30%;
    }
    .number-td:nth-child(5) .event,
    .number-td:nth-child(6) .event,
    .number-td:nth-child(7) .event {
        left: 50%;
    }
    .calendar-head,
    .calendar-wrap {
        margin: 0 -15px;
    }
    .calendar-head {
        padding: 0 15px;
    }
    .calendar-number {
        padding: 10px 5px;
    }
    .calendar-table__head .calendar-td {
        padding-left: 5px;
    }
    .main-container {
        margin-top: 20px;
    }
    .invite__field {
        width: 100%;
    }
    .adminBox {
        flex-wrap: wrap;
    }
    .mainPanel {
        width: 100%;
        padding: 30px 15px;
    }
    .product-photo {
        width: 48%;
        margin-right: 4%;
    }
    .product-photo:nth-child(2n) {
        margin-right: 0;
    }
    .product-photo:nth-child(5n) {
        margin-right: 4%;
    }
    .product-photo:nth-child(10) {
        margin-right: 0;
    }
    .adminTable {
        border: 0;
    }
    .adminTable thead {
        display: none;
    }
    .adminTable tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: 2px solid #ddd;
    }
    .adminTable td {
        display: block;
        text-align: right;
        font-size: 13px;
        border-bottom: 1px dotted #ccc;
        min-width: unset;
        word-break: break-all;
    }
    .adminTable td:before,
    .adminTable tr:before {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        text-align: left;
    }
    .adminTable td:last-child {
        border-bottom: 0;
    }
    .adminTable td:before {
        content: attr(data-label);
    }
    .adminTable > tbody td {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        text-align: left;
        padding: 10px;
    }
    .adminTable tr:before {
        content: attr(data-label-tr);
        width: 100%;
        color: #fff;
        display: block;
        padding: 15px;
        background: #272c2b;
    }
    .adminTable colgroup {
        display: none; /* To suppress the width applied to columns */
    }
    .calendar-event > p,
    .calendar-event > span,
    .month {
        display: none;
    }
    .checkout {
        padding: 0 15px 15px;
    }
    .calendar-event {
        padding: 0;
        position: static;
    }
    .event {
        width: 290px;
        top: 0;
    }
    .calendar-wrap {
        margin-top: 20px;
        position: relative;
    }
    .calendar-event {
        display: block;
        margin: 5px;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background: #1b5bed;
        border: none;
    }
    .calendar-event--type2 {
        background: #8dc63f;
    }
    .calendar-event--type3 {
        background: #ec008c;
    }
    .calendar-event--type4 {
        background: #ff0;
    }
    .number-td.active {
        border-bottom: 2px solid #1b5bed;
    }
    .head-title {
        font-size: 1.0625em;
    }
    .calendar-inner {
        min-height: 30px;
    }
    .pagination__item {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .table-field {
        margin-top: 7px;
        width: 100%;
    }
    .switch-box {
        margin-top: 15px;
    }
}
@media screen and (max-width: 767px) {
    .calendar-time,
    .head-title {
        font-size: 0.75em;
    }
    .page-head {
        flex-wrap: wrap;
    }
    .page-head .page-add {
        width: 100%;
        justify-content: flex-start;
        margin-top: 10px;
    }
    .number-td--fixed {
        width: 70px;
    }
    .calendar-table--week .head-title {
        font-size: 0.75em;
        padding-bottom: 10px;
    }
    .calendar-table--day tbody tr td {
        padding: 0;
    }
    .calendar-table--day thead tr td {
        padding: 0 !important;
    }
    .categoryTitle {
        padding: 0 8px;
    }
    .mainPanel {
        padding: 20px 0;
    }
    .calendar-table thead tr td {
        padding-left: 5px;
    }
    .gallery-col {
        width: 100%;
    }
    .recommended-slider {
        justify-content: space-between;
        order: 2;
    }
    .table-select {
        flex-direction: column;
        align-items: flex-start;
    }
    .product-photo {
        width: 100%;
        margin-right: 0 !important;
    }
}
@media screen and (max-width: 420px) {
    .calendar-table--week .head-title {
        font-size: 0.625em;
    }
}
@media print {
    .adminBox {
        justify-content: flex-start;
    }
    .mainPanel {
        width: 100% !important;
        transform: scale(0.5) translate(-50%, -50%) !important;
        float: left;
    }
    .adminTable > tbody > tr {
        border-top: 1px solid #e1e1e1;
    }
    .adminTable,
    .td-title {
        border: 1px solid #e1e1e1;
    }
    .adminTable colgroup {
        display: none; /* To suppress the width applied to columns */
    }
    .print {
        display: none;
    }
}
