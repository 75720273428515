.control {
  transform: translateY(-50%);
  margin-left: auto;
  position: absolute;
  top: 50%;
  right: 0;
}

.arrow {
  background: 0 0;
  border: none;
  cursor: pointer;
}

.slider {
  padding: 50px 0;
  border-top: 1px solid #d8d8d8;
}

@media screen and (max-width: 992px) {
  .slider {
    padding: 15px 0 50px;
  }
}

@media screen and (max-width: 767px) {
  .control {
    position: static;
    text-align: right;
    margin-left: auto;
    margin-top: 15px;
  }
}
