.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.avatar {
  min-width: 50px;
  max-width: 100%;
  width: 105px;
  margin: 0.5rem;
}

.avatar.smallAvatar {
  width: 60px;
  margin: 0.25rem;
}
.avatar.tinyAvatar {
  width: 40px;
  margin: 0.2rem;
}

.name {
  text-align: center;
  color: #494949;
  font-size: 1rem;
  font-weight: 700;
}
.name a {
  color: inherit;
}

.level {
  margin-top: 4px;
  color: #fc990c;
  font-weight: 900;
}

.root.horizontal {
  flex-direction: row;
  align-items: stretch;
}
.root.horizontal .name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .root {
    flex-direction: row;
    align-items: stretch;
  }

  .avatar {
    width: 60px;
  }
  .avatar.smallAvatar {
    width: 45px;
    margin: 0.25rem;
  }
  .avatar.tinyAvatar {
    width: 30px;
    margin: 0.2rem;
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
}
