.articleCard {
  margin: 10px;
  width: calc(33% - 20px); /* max 3 cards per row */
}

@media screen and (max-width: 991px) {
  .articleCard {
    margin: 10px;
    width: calc(50% - 20px); /* max 2 cards per row */
  }
}

@media screen and (max-width: 767px) {
  .articleCard {
    margin: 10px 0;
    width: 100%; /* 1 card per row */
  }
}
