.shopBadge {
  display: flex;
  align-items: center;
}
.shopAvatarContainer {
  width: 60px;
  margin-right: 0.5rem;
}
.shopBadge h2 {
  margin: 0;
}
.shopName {
  font-size: 2.3125rem;
  font-weight: 300;
}
.shopName,
.shopName:focus,
.shopName:hover {
  color: #666;
}

@media screen and (max-width: 991px) {
  .shopName {
    font-size: 25px;
  }
}

@media screen and (max-width: 767px) {
  .shopName {
    font-size: 16px;
    font-weight: 700;
  }
}
