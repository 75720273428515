.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.label {
  color: #000;
  font-weight: 700;
  font-size: 18px;
}

.flag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 60px;
  padding: 0 30px 0 50px;
  background: #361d83;
  color: #fff;
  font-weight: 900;
  font-size: 1.3125em;
  white-space: nowrap;
}
.flag:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border: 30px solid transparent;
  border-left: 30px solid #fff;
}

.root.small .label {
  font-size: 14px;
}

.root.small .flag {
  height: 40px;
  padding: 0 15px 0 30px;
  font-size: 16px;
}

.root.small .flag:after {
  border-width: 20px;
  border-left-width: 20px;
}
