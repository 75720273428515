.root {
    background: #fafafa;
}

.root > h3 {
    font-weight: 900;
    text-transform: uppercase;
    color: #666;
}

.uploaderTriggerCell {
    position: relative;
    height: 100%;
}

.uploaderTriggerAnchor {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    font-size: 1.5em;
}

/* 5 cells per row */
.cellContainer {
    flex: 0 0 20%;
    max-width: 20%;
}

/* 1 cell per row */
.cellContainer.onlyOneCell {
    flex: 0 0 75%;
    max-width: 75%;
    margin: auto;
}

@media screen and (max-width: 1200px) {
    /* 3 cells per row */
    .cellContainer {
        flex: 0 0 33.3%;
        max-width: 33.3%;
    }

    /* 1 cell per row */
    .cellContainer.onlyOneCell {
        flex: 0 0 90%;
        max-width: 90%;
        margin: auto;
    }
}

@media screen and (max-width: 767px) {
    /* 1 cell per row */
    .cellContainer {
        flex: 0 0 100%;
        max-width: 100%;
    }

    /* 1 cell per row */
    .cellContainer.onlyOneCell {
        flex: 0 0 100%;
        max-width: 100%;
        margin: auto;
    }
}
