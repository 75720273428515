.root {
  display: inline-block;
  padding: 4px 8px;
  color: #a0a0a0;
  font-size: 0.75em;
  font-weight: 700;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

/* Hide the arrow of the first breadcrumb item */
.root:first-child i {
  display: none;
}

.root a,
.root a:focus,
.root a:hover {
  color: #a0a0a0;
}

.root:last-child a,
.root:last-child a:focus,
.root:last-child a:hover {
  color: #494949;
}
