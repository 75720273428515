.bookingBtnTheme1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  width: 96%;
  height: 100px;
  border-radius: 50px;
  border: none;
  background-color: #6b228b;
  color: #fff;
  box-shadow: 3px 3px 15px #999;
}
.bookingBtnTheme1 p {
  display: flex;
  align-items: center;
  margin: 0;
  letter-spacing: 8px;
  font-size: 32px;
}
.bookingBtnTheme1 span {
  font-size: 18px;
}

.bookingBtnTheme2 {
  display: block;
  margin: 0.5rem auto;
  width: 98%;
  border: none;
  border-radius: 3px;
  background-color: #512297;
  color: #fff;
  font-size: 26px;
  letter-spacing: 6px;
  box-shadow: 3px 3px 15px #999;
}
.bookingBtnTheme2 p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .bookingBtnTheme1 {
    height: 80px;
    border-radius: 40px;
  }
  .bookingBtnTheme1 p {
    font-size: 28px;
  }
  .bookingBtnTheme1 span {
    font-size: 14px;
  }
}
