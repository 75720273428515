.anchor {
  margin: 5px;
  border-radius: 10px;
  background-color: #295c1e;
  color: #f9f9f9;
  box-shadow: 3px 3px 15px #999;
}
.anchor:active,
.anchor:focus,
.anchor:hover {
  color: #fff;
}
.anchor i {
  font-size: 100px;
  text-shadow: 1px 1px 2px #eaeaea;
}
.anchor p {
  margin: 0;
  font-size: 30px;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px #eaeaea;
}

@media screen and (max-width: 1200px) {
  .anchor i {
    font-size: 80px;
  }
  .anchor p {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  .anchor i {
    font-size: 60px;
    text-shadow: none;
  }
  .anchor p {
    font-size: 16px;
    text-shadow: none;
  }
}
