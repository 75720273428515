.langSwitch {
  width: 45px;
  height: 45px;
  padding: 0 !important;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 700;
}
.langSwitch:hover {
  background: #361d83;
  border-color: #361d83;
  color: #fff;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 767px) {
  .langSwitch {
    width: 40px;
    height: 40px;
  }
}
