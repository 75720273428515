.scheduleColumn {
  width: 48%;
}

.schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ececec;
}

.scheduleBox {
  width: calc(100% - 105px);
  display: flex;
  align-items: center;
}

.scheduleGroup {
  display: flex;
  align-items: center;
  width: 50%;
}
.scheduleGroup p {
  width: 70px;
  text-align: center;
  margin: 0;
  padding: 0 5px;
  font-size: 0.875em;
  font-weight: 700;
  text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
  .scheduleGroup p {
    width: 50px;
    font-size: 12px;
  }
  .scheduleColumn {
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .scheduleColumn {
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .schedule {
    flex-direction: column;
    align-items: flex-start;
  }
  .scheduleBox {
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .scheduleGroup {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .scheduleGroup p {
    width: auto;
    padding: 10px 0;
  }
}
