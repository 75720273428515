.messageBubble {
  position: relative;
  padding: 12px;
  border-radius: 10px;
  margin: .5em auto;
  max-width: 75%;

  background: #D9FDD3;

  font-size: 1rem;
  color: #000;
}

/* The speech triangle */
.messageBubble::after {
  position: absolute;
  content: "";
  border: 20px solid transparent;
}

.left.messageBubble {
  margin-left: 20px;
}
.left.messageBubble::after {
  border-right-color: #D9FDD3;
  border-left: 0;
  top: 50%;
  left: -20px;
  margin-top: -20px;
}

.right.messageBubble {
  margin-right: 20px;
}
.right.messageBubble::after {
  border-left-color: #D9FDD3;
  border-right: 0;
  top: 50%;
  right: -20px;
  margin-top: -20px;
}

.messageCaption {
  margin-top: .5em;
  border-top: solid 1px #666;
  color: #666;
  font-size: .8em;
}
