.root {
  display: flex;
  align-items: center;
}
.anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.anchor,
.anchor:focus,
.anchor:hover {
  color: #fff;
}
.facebook {
  background: #1b5bed;
}
.twitter {
  background: #009eef;
}
