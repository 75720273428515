.checkable + label {
    display: flex;
    align-items: center;
    min-height: 40px;
    font-weight: 600;
    font-size: 0.875em;
}
.checkable + label > span {
    position: absolute;
    transform: translateY(-50%);
    color: #121212;
}
.checkable:checked,
.checkable:not(:checked) {
    position: absolute;
    left: -9999px;
}
.checkable:checked + label,
.checkable:not(:checked) + label {
    width: 100%;
    height: 50px;
    position: relative;
    padding-left: 40px !important;
    vertical-align: top;
    cursor: pointer;
}
.checkable:checked + label > span,
.checkable:not(:checked) + label > span {
    width: calc(100% - 30px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.checkable:not(:checked) + label {
    border: 1px solid #eaeaea;
    background: #fff;
}
.checkable:checked + label {
    border-color: transparent;
    background: #361d83;
}
.checkable:checked + label > span {
    color: #fff;
}
.checkable:checked + label:before,
.checkable:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 10px !important;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 2px solid #e7e7e7;
    background: #f5f5f5;
    border-radius: 3px;
}
.checkable:checked + label:after,
.checkable:not(:checked) + label:after {
    content: "\2713";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
    font-size: 12px;
    color: #494949;
    transition: all 0.2s;
}
.checkable:not(:checked) + label:after {
    opacity: 0;
}
.checkable:checked + label:after {
    opacity: 1;
    color: #fff;
}
.checkable:checked + label:before {
    border: 2px solid #fff;
    background: #361d83;
    color: #fff;
}