@charset "UTF-8";

* {
    outline: 0 !important;
}
body,
html {
    height: 100%;
}
body {
    font-family: Lato, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    color: #494949;
    background-color: #fff;
    position: relative;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
legend,
li,
mark,
menu,
nav,
object,
output,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

a,
a:active,
a:focus,
a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

/* smaller headings' font size */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}
.h1,
h1 {
    font-size: 1.75rem;
}
.h2,
h2 {
    font-size: 1.6rem;
}
.h3,
h3 {
    font-size: 1.45rem;
}
.h4,
h4 {
    font-size: 1.30rem;
}
.h5,
h5 {
    font-size: 1.15rem;
}
.h6,
h6 {
    font-size: 1rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

select {
    appearance: none;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

input::-ms-clear {
    width: 0;
    height: 0;
}

/*
input[type="date"]::-moz-clear {
    display: none;
}
input[type="date"]::-ms-clear,
input[type="date"]::-webkit-clear-button {
    display: none;
}
input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
    display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button {
    display: none;
}
*/

input[type="time"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}
input[type="time"]::-ms-clear {
    display: none;
}
input[type="time"]::-webkit-clear-button {
    display: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

input[type="text"]::-ms-clear {
    display: none;
}

textarea {
    min-height: 6rem;
}

footer a {
  color: #494949;
}

footer a:focus,
footer a:hover {
  color: #909090;
}

.MuiTooltip-tooltip {
    font-size: 1rem !important;
}
.MuiDialogTitle-root,
.MuiDialogActions-root {
    padding: 12px 24px !important;
}
.MuiDialogContent-root {
    padding: 6px 24px !important;
}
.MuiDialogActions-root {
    justify-content: flex-start !important;
}

.app-ico-btn {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 45px;
    background: 0 0;
    border: none;
    cursor: pointer;
}

.app-absolute-center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-spinner-block {
    display: block;
    margin: 1em auto;
}
.app-spinner-inline {
    width: 18px;
    margin-left: 0 4px;
    margin-right: 0 4px;
}

.app-sortable-handle {
    padding: 3px 6px;
}
.app-sortable-handle:hover {
    cursor: grab;
}
.app-sortable-handle:active {
    cursor: grabbing;
}
.app-sortable-state-reordering .app-sortable-handle {
    visibility: hidden;
}

.app-line-breakable {
    word-break: break-word;
    white-space: pre-wrap;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block;
}
.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    background: 0 0;
    outline: 0;
}
.slick-dots {
    bottom: -25px;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-slider {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
}
.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: 0;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
}
.slick-track {
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:after,
.slick-track:before {
    content: "";
    display: table;
}
.slick-track:after {
    clear: both;
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}
[dir="rtl"] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots,
.slick-next,
.slick-prev {
    position: absolute;
    display: block;
    padding: 0;
}
.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
}
.slick-prev {
    left: -25px;
}
[dir="rtl"] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    content: "←";
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
    content: "→";
}
.slick-next {
    right: -25px;
}
[dir="rtl"] .slick-next {
    right: auto;
    left: -25px;
}
[dir="rtl"] .slick-next:before {
    content: "←";
}
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #000;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
}

.navbar-toggle {
    width: 36px;
    height: 36px;
    background: #FFF;
    border: solid 1px #6c757d;
    border-radius: 50%;
    color: #6c757d;
    font-size: .875rem;
}
.navbar-toggle:hover {
    background: #DDD;
}

.mob-slider-control {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -50px;
    right: 48px;
}
.mob-slider-control--next {
    right: 0;
}
.mob-slider-control button {
    width: 36px;
    height: 36px;
    text-align: center;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
    background: 0 0;
    padding: 0;
    border: none;
    color: #361d83;
    font-size: 16px;
    line-height: 1;
}
.mob-slider-control button + button {
    margin-left: 10px;
}

.close-ico:after,
.close-ico:before,
.temp-cover:after {
    content: "";
}
.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.content {
    flex: 1 0 auto;
}
.field-wrap {
    position: relative;
}
.field {
    margin-bottom: 1.8em;
}
.field-help {
    font-size: 0.875em;
}
.ie-container {
    text-align: center;
    padding: 30px 15px;
}
.ie-close {
    border: none;
    background: 0 0;
}
.form-item {
    width: 100%;
    height: 44px;
    border: 2px solid #dedede;
    border-radius: 4px;
    background: #fff;
    padding: 0 20px;
    font-size: 0.875em;
}
select.form-item {
    appearance: none;
    background: #FFF url(../img/select-arrow.png) calc(100% - 15px) 50% no-repeat;
    padding: 0 36px 0 20px;
}
.form-item:disabled,
input.form-item:read-only {
    background-color: #ebebe4 !important;
}
.form-item::-webkit-input-placeholder {
    color: #494949;
    font-weight: 300;
}
.form-item::-moz-placeholder {
    color: #494949;
    font-weight: 300;
}
.form-item:-ms-input-placeholder {
    color: #494949;
    font-weight: 300;
}
.form-item:-moz-placeholder {
    color: #494949;
    font-weight: 300;
}
.form-item::-ms-clear {
    display: none;
}
.form-item--area {
    padding: 10px 20px;
    min-height: 120px;
    overflow-x: hidden;
    resize: vertical;
}
.form-item--ico-right {
    padding-right: 40px !important;
}
.form-item--ico-left {
    padding-left: 40px !important;
}
div.form-item {
    display: flex;
    align-items: center;
}
.error-field .error-text {
    display: block;
}
.field-ico {
    position: absolute;
    width: 25px;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #c5c5c5;
    line-height: 1;
}
.field-ico--to-right {
    left: unset !important;
    right: 20px;
}
.theme-btn,
.theme-btn:hover {
    color: #fff;
}
.error-text {
    position: absolute;
    min-width: 120px;
    right: 0;
    top: calc(100% + 10px);
    padding: 10px;
    background: #ef3a41;
    border-radius: 3px;
    font-size: 10px;
    width: auto;
    z-index: 98;
    color: #fff;
}
.error-text:after {
    position: absolute;
    border: 5px solid transparent;
    border-bottom: 5px solid #ef3a41;
    transform: none;
    top: -9px;
    right: 9px;
    content: "";
}
.field-label {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    padding: 15px 0;
    font-size: 0.875em;
}
.app-required:after {
    color: #e32;
    content: ' *';
}
.theme-title {
    font-weight: 300;
}
.theme-modal__header--code h2,
.theme-subtitle {
    text-transform: uppercase;
}
.grey-block {
    height: 50px;
    background-color: #f5f5f5;
}
.section-info {
    padding: 50px 0;
    text-align: center;
}
.section-info__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    margin: 0 auto;
    height: 50px;
    border-radius: 50%;
    background-color: #1b5bed;
    color: #fff;
}
.theme-title {
    font-size: 2.34375em;
}
.theme-btn {
    display: block;
    background: #361d83;
    height: 48px;
    line-height: 46px;
    width: 120px;
    border-radius: 24px;
    text-align: center;
    font-size: 0.875em;
    font-weight: 500;
    border: none;
    cursor: pointer;
}
.theme-btn:disabled {
    background-color: #b5b5b5 !important;
    cursor: not-allowed;
}
.pass-section {
    background-color: #f5f5f5;
    padding: 30px 0;
}
.pass-box {
    background-color: #fff;
    padding: 30px 15px;
}
.nav-search__field {
    width: 100%;
    height: 46px;
    border-radius: 23px;
    border: 1px solid #dedede;
    padding-left: 40px;
    padding-right: 20px;
}
.theme-modal__header--code {
    border-top: 5px solid #361d83;
}

/* For Material UI Dialog */
.app-dialog-title {
    border-top: 5px solid #361d83;
}
.app-dialog-actions {
    background: #f6f6f6;
    padding: 24px 20px !important;
}

.modal-wrapper {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 199;
    background: rgba(0, 0, 0, 0.2);
}
.modal-wrapper__inner {
    margin: auto;
    position: relative;
}
.modal-wrapper__inner .event {
    position: relative;
    top: 0;
    right: 0;
}
.theme-modal {
    position: relative;
    box-shadow: 0 -5px 65px 0 rgba(0, 1, 1, 0.11);
    width: 470px;
    margin: 0 auto;
    z-index: 198;
    background: #fff;
}
.theme-modal--album {
    width: 860px;
}
.theme-modal__header {
    position: relative;
    padding: 20px 45px;
}
.theme-modal__header h2 {
    font-size: 2em;
    font-weight: 300;
}
.theme-modal__header--code h2 {
    color: #666;
    font-weight: 700;
}
.theme-modal__body {
    padding: 20px 45px;
}
.theme-modal__content {
    padding: 20px 0;
    background: #fff;
}
.modal-inner-content {
    padding: 0 45px 15px;
}
.close-modal {
    position: absolute;
    top: 10px;
    right: 5px;
    background: 0 0;
    border: none;
    cursor: pointer;
    z-index: 2;
}
.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: 0 0;
    color: #2a2a2a;
    font-size: 1.125em;
    cursor: pointer;
}
.forgot-pass {
    padding: 0;
    border: none;
    background: 0 0;
    color: #007bff;
    font-size: 1.0625em;
    text-decoration: underline;
}
.forgot-pass,
.forgot-pass:focus,
.forgot-pass:hover {
    color: #272c2b;
}
.cancel-box {
    display: none;
    position: absolute;
    top: calc(100% + 15px);
    width: 100%;
    padding: 15px;
    left: 0;
    background: #fff;
    border: 1px solid #ececec;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2);
    z-index: 10;
}
.cancel-box.active {
    display: block;
}
.triangle {
    position: absolute;
    left: 45px;
    top: -20px;
    display: inline-block;
    color: #fff;
    text-shadow: 0 0 0 rgba(255, 255, 255, 0.7), 0 -1px 1px rgba(221, 221, 221, 0.9);
    font-size: 18px;
}
.temp-cover {
    background-color: #d3d3d3;
    position: relative;
}
.temp-cover:after {
    display: block;
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    border: 1px dashed #adadad;
}
.temp-cover--no-border:after {
    display: none;
}
.slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(89, 89, 87, 0.8);
    color: #fff;
    border: none;
    cursor: pointer;
}
.slider-btn--right {
    left: auto;
    right: 0;
}
.temp-ico {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 2.25em;
    color: #b4b4b4;
}
.temp-ico--type2 {
    font-size: 0.875em;
}
.theme-radio {
    vertical-align: top;
}
.theme-radio + label {
    cursor: pointer;
}
.theme-radio:not(checked) {
    position: absolute;
    opacity: 0;
}
.theme-radio:not(checked) + label {
    width: 100%;
    position: relative;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 0 0 0 30px;
    font-size: 12px;
}
.theme-radio:not(checked) + label > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    color: #494949;
}
.theme-radio:not(checked) + label:before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #404040;
    border-radius: 50%;
    background: #fff;
}
.theme-radio:not(checked) + label:after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #404040;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.2s;
}
.theme-radio:checked + label:after {
    opacity: 1;
}
.section-title {
    padding: 30px 0;
    position: relative;
    text-align: center;
    line-height: 1;
}
.section-title > h2 {
    font-size: 2.3125em;
    font-weight: 300;
}
.slider-control {
    position: absolute;
    top: 50%;
    margin-left: auto;
}
.slider-control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translateY(-50%);
    left: 15px;
    border: none;
    background: rgba(17, 17, 17, 0.6);
    color: #fff;
    cursor: pointer;
    z-index: 3;
}
.slider-control--right {
    left: unset;
    right: 15px;
}
.breadcrumps-section {
    background-color: #fff;
    padding-bottom: 15px;
}
.shop-news {
    padding: 10px;
}
.shop-news h5 {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 1rem;
}
.shop-news-attachment-box {
    display: flex;
    justify-content: flex-start;
}
.shop-news-attachment-thumb {
    width: 50px;
    margin-right: 10px;
    cursor: pointer;
}
.shop-news .more-link {
    font-size: 1rem;
    margin: 0;
    padding: 8px 0;
}
.shop-news .more-link,
.shop-news .more-link:focus,
.shop-news .more-link:hover {
    color: #5a4794;
}

.news-item-container {
    margin-top: 30px;
    border: 1px solid #ececec;
    padding: 30px;
}
.news-item-container:last-child {
    margin-bottom: 30px;
}
.news-item-container:hover {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);
    transition: all 0.3s ease;
}
.news-title {
    font-size: 1.3125rem;
    font-weight: 900;
}
.news-title,
.news-title:hover {
    color: #494949;
}
.news-info {
    margin-top: 5px;
    color: #aeaeae;
    font-weight: 900;
    font-size: 0.875em;
}
.news-content {
    margin: 0 0 1rem;
    color: #2a2a2a;
    font-size: .9375rem;
    font-weight: 400;
}

.sidebar-group {
    position: relative;
    padding: 0 10px;
}
.sidebar-group + .sidebar-group {
    border-top: 1px solid #ececec;
}
.sidebar-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
}
.sidebar-title__ico {
    color: #afafaf;
}
.sidebar-title h2 {
    font-size: 0.8125em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    margin: 0;
}
.sidebar-title--full h2 {
    overflow: visible;
    text-overflow: initial;
    white-space: initial;
}
.sidebar-title__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #f6f6f6;
}
.sidebar-title__link {
    display: flex;
    align-items: center;
    color: #afafaf;
    font-size: 12px;
    font-weight: 700;
}
.shop-schelude {
    display: flex;
}
.sidebar-content {
    padding-bottom: 20px;
}
.about-shop {
    color: #b7b7b7;
    font-weight: 700;
}
.about-shop a,
.about-shop a:focus,
.about-shop a:hover {
    color: #757575;
}
.about-shop a {
    display: block;
    text-align: right;
    font-weight: 900;
    text-decoration: underline;
}
.shop-schelude .shop-schelude-weekday {
    font-weight: 900;
    color: #c1c1c1;
    font-size: 0.75em;
}
.shop-schelude .shop-schelude-workinghour {
    font-weight: 500;
    color: #666;
    font-size: 0.8125em;
}
.shop-schelude + .shop-schelude {
    margin-top: 10px;
}
.theme-subtitle {
    display: block;
    margin: 0;
    padding: 10px 0;
    font-weight: 900;
    font-size: 0.875em;
    color: #494949;
}
.rate-star {
    color: #f9ad3d;
    font-size: 14px;
}
.shop-main__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
}
.shop-label {
    margin: 3px;
    padding: 5px 12px;
    background-color: #361d83;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    font-size: 0.68rem;
    text-transform: uppercase;
}
.recomended__text p {
    font-weight: 300;
    font-size: 0.8125em;
}
.shop-labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}
.product-slider__main {
    position: relative;
    height: 270px;
}
.product-slider__main > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product-slider__box {
    display: flex;
    margin-top: 15px;
}
.latest-review {
    margin-top: 30px;
}
.slider-preview {
    width: 24%;
    margin-right: calc(4% / 3);
}
.slider-preview:last-child {
    margin-right: 0;
}
.latest-review-text {
    margin-top: 10px;
    line-height: 1.6;
    font-weight: 300;
    font-size: 0.8125em;
}
.latest-review-text a {
    color: inherit;
}
.shop-group {
    margin-top: 30px;
}
.shop-gallery {
    display: flex;
    margin-top: 20px;
}
.shop-gallery__item {
    display: inline-block;
    height: 50px;
    width: 50px;
    margin: 3px;
    cursor: pointer;
}
.shop-gallery__item > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.recomended {
    display: flex;
    margin-top: 20px;
}
.recomended__img {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 3px;
}
.recomended__img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}
.recomended__text {
    width: calc(100% - 60px);
    color: #666;
    padding-left: 15px;
    max-width: 80%;
}
.shop-book-col .react-date-picker {
    display: block;
}
.shop-book-col .react-date-picker__wrapper {
    border: none;
    height: 100%;
}
.shop-book-col .react-date-picker__button {
    padding-right: 0;
}
.shop-book-col .react-date-picker__button + button {
    margin-right: -2px;
}
.shop-book-col .react-date-picker__button svg,
.shop-book-col .react-date-picker__button:focus svg,
.shop-book-col .react-date-picker__button:hover svg {
    width: 15px;
}
.shop-book-col .react-date-picker__button svg g {
    stroke: #c5c5c5;
}
.shop-book-col .react-date-picker__button:focus svg g,
.shop-book-col .react-date-picker__button:hover svg g {
    stroke: #c5c5c5 !important;
}
.recomended__text span {
    font-size: 0.8125em;
    font-weight: 700;
}
.news-share {
    margin-top: 20px;
    display: flex;
}
.close-ico {
    display: block;
}
.news-share__item,
.news-share__item:focus,
.news-share__item:hover {
    color: #c1c1c1;
}
.news-share__item + .news-share__item {
    margin-left: 10px;
}
.more-link {
    text-decoration: underline;
    display: block;
    margin-top: 10px;
    font-size: 0.8125em;
    font-weight: 700;
}
.more-link,
.more-link:focus,
.more-link:hover {
    color: #757575;
}
.more-link--type2 {
    margin-top: 30px;
    text-align: center;
}
.shop-book-col .error-text {
    max-width: 100%;
    bottom: calc(100% + 3px);
    top: unset;
    padding: 10px 5px;
    line-height: 1.2;
    font-size: .8rem;
}
.shop-book-col .error-text:after {
    transform: rotate(180deg);
    top: unset;
    bottom: -9px;
}
.book-window {
    border-top: 5px solid #361d83;
}
.book-window .MuiDialogContent-root {
    padding: 8px 12px;
}
.close-field-ico {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: 0 0;
    border: none;
    padding: 0;
    cursor: pointer;
}
.close-ico {
    position: relative;
    width: 20px;
    height: 20px;
}
.close-ico:after,
.close-ico:before {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #494949;
}
.close-ico:before {
    transform: translate(-50%, -50%) rotate(45deg);
}
.close-ico:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}
.review-container {
    position: relative;
    margin-top: 30px;
    padding: 15px 30px 15px;
    border: 1px solid #ececec;
}
.review-container--type3 {
    background: #fff;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);
}
.review-title {
    font-size: 1.3125rem;
    font-weight: 900;
}
.review-title,
.review-title:focus,
.review-title:hover {
    color: #494949;
}
.review-info {
    margin-top: 5px;
    color: #aeaeae;
    font-size: 13px;
    font-weight: 900;
    word-spacing: 7px;
}
.review-content {
    color: #2a2a2a;
    font-weight: 400;
    font-size: 0.9375em;
}
.review-baner {
    width: 100%;
    height: 300px;
    margin-top: 20px;
}
.review-baner > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.review-baner + .review-baner {
    margin-top: 15px;
}
.member-footer {
    margin-top: 60px;
    padding: 30px 0 0;
    display: flex;
    justify-content: center;
    border-top: 1px solid #dedede;
}
.checkbox + label {
    display: flex;
    align-items: center;
    min-height: 40px;
    font-weight: 600;
    font-size: 0.875em;
}
label[for] {
    cursor: pointer;
}
.checkbox + label > span {
    color: #494949;
    position: absolute;
    transform: translateY(-50%);
    font-weight: 300;
}
.checkbox + label > span a,
.checkbox + label > span a:not([href]):not([tabindex]) {
    color: #0056b3;
    font-weight: 400;
}
.checkbox:checked,
.checkbox:not(:checked) {
    position: absolute;
    left: -9999px;
}
.checkbox:checked + label,
.checkbox:not(:checked) + label {
    width: 100%;
    min-height: 40px;
    position: relative;
    padding-left: 30px !important;
    vertical-align: top;
}
.checkbox:checked + label > span,
.checkbox:not(:checked) + label > span {
    width: calc(100% - 30px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.checkbox--type2:checked + label > span,
.checkbox--type2:not(:checked) + label > span {
    text-transform: uppercase;
    font-weight: 900;
}
.checkbox--type3:checked + label > span,
.checkbox--type3:not(:checked) + label > span {
    font-weight: 700;
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0 !important;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 2px solid #e7e7e7;
    background: #fff;
    border-radius: 3px;
}
.checkbox:checked + label:after,
.checkbox:not(:checked) + label:after {
    content: "\2713";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    font-size: 12px;
    color: #494949;
    transition: all 0.2s;
}
.checkbox:not(:checked) + label:after {
    opacity: 0;
}
.checkbox:checked + label:after {
    opacity: 1;
}
.checkbox--type2:checked + label:before,
.checkbox--type3:checked + label:before {
    background: #361d83;
    border-color: #361d83;
}
.checkbox--type2:checked + label::after,
.checkbox--type3:checked + label::after {
    color: #fff;
    opacity: 1;
}
.member-check {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 60px;
    margin-top: 20px;
}
.terms-check {
    color: #0056b3;
}
.shop-name-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background: #1b5bed;
    color: #fff;
    font-weight: 700;
    border-radius: 4px;
}
.shop-name-info > span {
    padding-right: 15px;
}
.member-title {
    margin-top: 15px;
    padding: 20px 0 15px;
    font-size: 1.3125em;
    font-weight: 300;
}
.member-info {
    margin: 15px 0;
    font-size: 1.3125em;
    font-weight: 300;
}
.member-info span {
    font-weight: 500;
    color: #666;
}
.member-extra {
    padding: 30px 0;
}
.member-extra p {
    font-size: 1.125em;
    font-weight: 300;
}
.member-extra p > span {
    font-weight: 500;
    color: #666;
}
.member-extra p a {
    font-weight: 500;
}
.news-prev-img {
    width: 100%;
    height: 50px;
    margin-top: 5px;
}
.news-prev-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.price-item {
    position: relative;
    width: 250px;
}
.price-item:hover {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);
    transition: all 0.3s ease;
}
.price-item__name {
    color: #666;
    font-size: 0.9375em;
    font-weight: 700;
    text-transform: uppercase;
}
.stylist__name {
    font-weight: 600;
}
.stylist:hover {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);
    transition: all 0.3s ease;
}
.stylist__text {
    font-size: 0.875em;
    font-weight: 500;
}
.shop-card {
    position: relative;
    display: block;
    align-self: stretch;

    margin: 10px;
    width: calc(33% - 20px); /* max 3 cards per row */
    padding: 16px 8px;

    background: #fff;
    border: 1px solid #ececec;
    text-align: center;
}
.shop-card:hover {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);
    transition: all 0.3s ease;
}
.shop-card__city {
    font-weight: 700;
    text-align: center;
    margin: 8px;
    background: #f5f5f5;
    padding: 8px;
    border: 1px solid #ececec;
    text-transform: uppercase;
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 0.75em;
}
.pages p {
    letter-spacing: 0.075em;
}
.items-result,
.upload-photo__text p {
    font-weight: 900;
}
.items-result {
    font-size: 0.875em;
}
.settings-title {
    margin: 30px 0;
    background: #ebebeb;
    padding: 10px 15px;
    font-weight: 900;
}
.upload-photo {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px;
    background: #f5f5f5;
}
.upload-photo__text {
    position: relative;
    width: calc(100% - 100px);
    padding-left: 15px;
}
.upload-photo__text span {
    display: block;
    font-weight: 700;
    margin-top: 10px;
    font-style: italic;
}
.upload-photo .error-text {
    right: unset;
    top: 90%;
    left: 30px;
}
.upload-photo .error-text:after {
    right: unset;
    left: 9px;
}
.upload-photo--customer-settings {
    margin-top: 15px;
}
.shop-avatar .error-text {
    right: unset;
    top: calc(100% + 10px);
    left: 0;
}
.shop-avatar .error-text:after {
    right: unset;
    left: 9px;
}
.upload-input,
.upload-input__ico,
.upload-input__item {
    position: absolute;
    left: 50%;
    top: 50%;
}
.upload-input {
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    overflow: hidden;
    cursor: pointer;
}
.upload-input__item {
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.upload-input__ico {
    font-size: 1.5em;
    color: #b5b5b5;
    transform: translate(-50%, -50%);
}
.onoffswitch {
    position: relative;
    width: 90px;
    margin: 0 auto;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:after,
.onoffswitch-inner:before {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    font-weight: 700;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #34c240;
    color: #fff;
    text-align: left;
}
.onoffswitch--chinese .onoffswitch-inner:before {
    content: "開啟";
}
.onoffswitch-inner--type2:before {
    background-color: #1875f0;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #eee;
    color: #999;
    text-align: right;
}
.onoffswitch--chinese .onoffswitch-inner:after {
    content: "關閉";
}
.onoffswitch-inner--work-type:after {
    content: "day off";
}
.onoffswitch--chinese .onoffswitch-inner--work-type:after {
    content: " 休息";
}
.onoffswitch-inner--work-type:before {
    content: "working";
}
.onoffswitch--chinese .onoffswitch-inner--work-type:before {
    content: "營業";
}
.onoffswitch-switch {
    display: block;
    width: 18px;
    height: 18px;
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 56px;
    border: 2px solid #999;
    border-radius: 50%;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.event {
    top: 15px;
    position: absolute;
}
.pages p {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 0.9375em;
}
.pages {
    padding: 30px 0;
    text-align: right;
}
.event-extra p,
.event-info p {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
.message-head {
    display: flex;
    margin-top: 30px;
    align-items: center;
    background: #494949;
    padding: 20px 30px;
    justify-content: space-between;
}
.message-head h2 {
    color: #fff;
    font-size: 2em;
    font-weight: 300;
}
.event {
    right: -365px;
    display: none;
    width: 350px;
    background: #fff;
    border: 1px solid #ececec;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2);
    z-index: 30;
}
.event.active {
    display: block;
}
.event__close {
    position: absolute;
    right: 15px;
    top: 10px;
    background: 0 0;
    border: none;
    cursor: pointer;
}
.event-btn {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}
.event-block .form-item .react-date-picker__wrapper .react-date-picker__calendar-button,
.form-item .react-date-picker__wrapper .react-date-picker__calendar-button,
.form-item .react-daterange-picker__calendar-button {
    display: none;
}
.event-block {
    background: #fff;
    padding: 15px 20px;
    border-bottom: 1px solid #dadee7;
}
.event-block:last-child {
    border: none;
}
.event-block .form-item .react-date-picker__wrapper {
    border: none;
    width: 100%;
    padding-left: 0;
}
.event-block .form-item .react-date-picker__wrapper .react-date-picker__clear-button {
    padding: 0;
}
.event-block .react-date-picker__calendar--above-label {
    bottom: unset;
}
.react-date-picker.form-item {
    padding: 0 15px;
}
.react-date-picker.form-item.react-date-picker--enabled {
    cursor: pointer;
}
.react-date-picker.form-item--ico-left {
    padding-left: 40px;
}
.react-daterange-picker.form-item {
    padding: 0 12px 0 15px;
}
.form-item .react-date-picker__wrapper {
    border: none;
    width: 100%;
    padding-left: 0;
}
.form-item .react-date-picker__wrapper .react-date-picker__clear-button,
.form-item .react-date-picker__wrapper .react-date-picker__inputGroup {
    padding: 0;
}
.form-item .react-daterange-picker__wrapper {
    border: none;
    width: 100%;
    padding-left: 0;
    align-items: center;
}
.form-item .react-daterange-picker__inputGroup {
    padding: 0;
    min-width: auto;
    flex-grow: unset;
}
.form-item .react-daterange-picker__clear-button {
    display: block;
    margin-left: auto;
    padding: 0;
}
.react-date-picker__calendar {
    z-index: 99 !important;
}
.event-info p {
    padding: 10px 0 5px;
    color: #8492af;
    font-weight: 900;
    font-size: 0.75em;
}
.event-info span {
    color: #494949;
    font-weight: 700;
    font-size: 1.125em;
}
.event-info span.field-ico {
    color: #717171;
}
.event-info + .event-info {
    margin-top: 10px;
}
.event-info .field-ico {
    font-size: 0.98em;
    right: 15px;
    line-height: 1;
    display: block;
    margin-top: -0.4px;
}
.event-info .field-wrap .form-item {
    padding: 0 5px;
    height: 30px;
}
.event-extra {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event-extra p {
    margin-bottom: 0;
    color: #8492af;
    font-weight: 900;
    font-size: 0.75em;
}
.event-extra span {
    font-weight: 500;
    font-size: 15px;
}
.book-tab__item {
    letter-spacing: 0.075em;
}
.event-control {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.event-control__item {
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    background: 0 0;
    border: 1px solid #e5e5e5;
    color: #494949;
    cursor: pointer;
}
.event-control__item + .event-control__item {
    margin-left: 10px;
}
.cust-select-box {
    width: 200px;
    position: relative;
    z-index: 10;
}
.cust-select-box--less-index {
    z-index: 5;
}
.cust-select-field {
    position: relative;
    width: 100%;
    border-radius: 3px;
    z-index: 20;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #ececec;
    color: #b2b2b2;
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.cust-select__option {
    text-align: left;
    color: #b2b2b2;
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.calendar-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.calendar-event {
    position: relative;
    margin-top: auto;
    width: 100%;
    padding: 5px;
    background: #f6f7fa;
    border-left: 4px solid #1b5bed;
    cursor: pointer;
}
.calendar-event > p {
    font-weight: 700;
    font-size: 13px;
}
.calendar-event > span {
    font-weight: 500;
    font-size: 13px;
}
.calendar-event + .calendar-event {
    margin-top: 3px;
}
.calendar-event--type2 {
    border-left: 4px solid #8dc63f;
}
.table-select {
    display: flex;
    align-items: center;
}
.select-group + .select-group {
    margin-left: 15px;
}
.album-buy,
.album-buy:hover,
.album-info {
    color: #ee8906;
}
.month-select__btn {
    top: 50%;
}
.month-select {
    width: 320px;
    position: relative;
    padding: 0 20px;
}
.month-select__box {
    width: 290px;
    text-align: center;
}
.month-select__btn {
    position: absolute;
    background: 0 0;
    border: none;
    transform: translateY(-50%);
    cursor: pointer;
}
.month-select__btn--left {
    left: -10px;
}
.month-select__btn--right {
    right: -10px;
}
.month-select__item {
    font-size: 2.625em;
    font-weight: 300;
}
.album-info__count,
.select-group p {
    font-weight: 900;
    text-transform: uppercase;
}
.select-group {
    display: flex;
    align-items: center;
}
.select-group p {
    font-size: 0.875em;
    margin: 0 10px 0 0;
}
.album-info {
    background: #ffefdb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px dashed #ee8906;
    border-radius: 5px;
    margin-bottom: 30px;
}
.album-info__count {
    font-size: 0.9375em;
}
.album-buy {
    display: inline-flex;
    padding: 5px 20px;
    border: 1px solid #ee8906;
    border-radius: 3px;
    margin: 0 10px;
    cursor: pointer;
}
.book-tab__item:after,
.product-price__old:after {
    top: 50%;
    content: "";
}
.book-tab {
    padding-top: 30px;
    padding-bottom: 30px;
    border: 1px solid #ececec;
    height: 100%;
}
.book-tab__item {
    position: relative;
    display: block;
    padding: 10px 30px;
    color: #361d83;
    font-weight: 700;
    font-size: 0.875em;
}
.book-tab__item + .book-tab__item {
    margin-top: 10px;
}
.book-tab__item:after {
    position: absolute;
    right: 0;
    width: 2px;
    height: 40px;
    transform: translateY(-50%);
    background: #361d83;
    opacity: 0;
}
.book-tab__item.active:after {
    opacity: 1;
}
.book-tab__item:hover {
    color: #361d83;
}
.book-section {
    background: #f5f5f5;
}
.staff-user__text a {
    color: #494949;
    font-weight: 900;
}
.staff-about {
    margin-top: 30px;
    padding: 30px;
    background: #f5f5f5;
    color: #494949;
}
.staff-about h2 {
    font-weight: 900;
    text-transform: uppercase;
}
.staff-about article {
    margin-top: 15px;
    font-size: 0.8125em;
    line-height: 2;
    font-weight: 400;
}
.staff-info-row {
    display: flex;
    margin-bottom: 12px;
    color: #361d83;
    font-size: 0.8125em;
    font-weight: 700;
    text-transform: uppercase;
}
.staff-info-row .staff-info-ico {
    min-width: 24px;
    margin-right: 6px;
    text-align: center;
}
.staff-info-row .staff-info-content {
    flex: 1 1 auto !important;
}
.staff-info-row .staff-info-content a {
    color: inherit;
}
.staff-info__title {
    display: inline;
    text-transform: uppercase;
    color: #361d83;
    font-weight: 700;
}
.staff-info__title span {
    padding-right: 10px;
}
.staff-info-block.staff-info-block-current-job .staff-info-row {
    font-size: 16px;
}
.work-slider .slick-next:before,
.work-slider .slick-prev:before {
    color: #000;
    font-size: 25px;
}
.work-slider {
    position: relative;
    margin: 30px 0 15px;
}
.work-slider .slick-list {
    margin: 0 -10px;
}
.work-slider .slick-list .slick-slide {
    padding: 0 10px;
}
.work-slider__inner {
    display: flex;
    padding: 0 120px;
}
.work-slider__item {
    position: relative;
    height: 180px;
    background: #f5f5f5;
    width: 24%;
}
.work-slider--interested .slider-control {
    left: -15px;
}
.work-slider--interested .slider-control--right {
    left: unset;
    right: -15px;
}
.work-slider--interested .work-slider__inner {
    padding: 0;
    margin: 0 -15px;
}
.work-slider--interested .work-slider__inner .product-item {
    margin-top: 0;
}
.work-slider .slick-prev {
    left: 10px;
    z-index: 1;
}
.work-slider .slick-next {
    right: 10px;
    z-index: 1;
}
.comment-section {
    margin-top: 50px;
    background: #f9f9f9;
    padding: 30px 0 60px;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
}
.comment-control__item {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #fff;
    box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.13);
    color: #666;
    border: none;
    cursor: pointer;
}
.comment-control__item:hover {
    color: #fff;
    background: #666;
    transition: all 0.3s ease;
}
.comment-control__item + .comment-control__item {
    margin-left: 10px;
}
.product-price {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.product-price__new {
    color: #ed2e2e;
    font-size: 1.75em;
    font-weight: 900;
}
.product-price__new span {
    font-size: 23px;
    font-weight: 400;
}
.product-price__new span.price-comment {
    font-size: 13px;
}
.product-price__new--type2 {
    color: #494949;
}
.product-price__old {
    position: relative;
    color: #939393;
    font-weight: 800;
    font-size: 1.1875em;
}
.product-price__old:after {
    position: absolute;
    left: -5px;
    margin-top: 2px;
    transform: translateY(-50%);
    width: calc(100% + 10px);
    height: 1px;
    background: #939393;
}
.product-price--booking-type {
    margin-top: 5px;
    line-height: 1.2;
}
.price-comment {
    padding-right: 10px;
    font-size: 13px;
}
.product-item {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #ececec;
    padding: 20px 0;
}
.product-item__img {
    width: 100%;
    height: 100px;
    margin-top: -15px;
}
.product-item__img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product-item__photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto;
    border: 2px solid #fff;
}
.product-item__photo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.product-item__name {
    text-align: center;
    font-weight: 900;
    font-size: 0.9375em;
}
.product-item__text {
    font-size: 16px;
    padding: 15px 0;
    margin: auto;
    width: calc(100% - 24px);
    overflow: hidden;
}
.product-item--service-type {
    position: relative;
    margin-top: 40px;
}
.product-item--service-type .product-item__img {
    margin-top: 0;
    height: 120px;
}
.product-item--service-type .price-sicker {
    margin-bottom: -40px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.product-item--service-type .product-item__photo {
    position: absolute;
    right: 0;
    top: -30px;
}
.product-item:hover {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);
    transition: all 0.3s ease;
}
.slider-arrow {
    display: flex;
    align-items: center;
}
.slider-arrow__item {
    background: 0 0;
    border: none;
    color: #f0f0f0;
    cursor: pointer;
}
.slider-arrow__item:hover {
    color: #494949;
}
.search-panel {
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.09);
}
.filter-item {
    padding: 5px 0;
}
.review-check {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    z-index: 5;
}
.customer-info__level {
    color: #fc990c;
    font-weight: 900;
}
.pay-block__title {
    text-transform: uppercase;
}
.review-head__title {
    color: #361d83;
    font-weight: 700;
    font-size: 1.3125em;
}
.extra-label {
    display: block;
    margin-bottom: 5px;
    color: #361d83;
    font-weight: 700;
}
.review-gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 15px -15px 0;
}
.review-gallery__item {
    position: relative;
    width: 24%;
    height: 200px;
    margin: 0 15px;
    background-color: #e2e2e0;
}
.review-gallery > .gallery-col > .gallery-item {
    margin-top: 0;
}
.about-user {
    color: #bababa;
    font-size: 0.8125em;
    font-weight: 300;
    line-height: 2;
}
.profile-statistic {
    display: flex;
    align-items: center;
    background: #ebebeb;
}
.profile-statistic__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 14px 21px 0 rgba(0, 0, 0, 0.11);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
}
.profile-statistic__title {
    font-weight: 900;
}
.book-stat-list {
    font-weight: 700;
}
.book-stat-list > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.book-stat-list > li span {
    color: #361d83;
}
.book-stat-list > li + li {
    margin-top: 10px;
}
.latest-review-section {
    background: #f9f9f9;
    border-top: 2px solid #ececec;
    border-bottom: 2px solid #ececec;
}
.latest-img-item {
    width: 20%;
    padding: 0 5px;
}
.latest-img-item .news-prev-img {
    margin: 0;
}
.service-block__title {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #361d83;
    color: #fff;
    font-weight: 700;
    line-height: 1.4;
    font-size: 1.25em;
}
.service-block:hover {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);
    transition: all 0.3s ease;
}
.single-service-price {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}
.user-profile-name {
    color: #361d83;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
.logout-btn {
    background: 0 0;
    border: none;
    font-size: 15px;
    padding: 0;
    color: #6d6d6d;
    font-weight: 600;
}
.user-profile-name:focus,
.user-profile-name:hover {
    color: #361d83;
}
.settings__btn {
    color: #361d83;
    font-size: 15px;
}
.settings__btn:hover {
    color: #361d83;
}
.confirm-code-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #f6f6f6;
    padding: 30px 45px;
}
.calendar-wrap {
    position: relative;
}
.home-links {
    display: flex;
    justify-content: center;
    padding: 30px 15px 15px;
}
.home-link {
    width: 200px;
    padding: 0 15px;
}
.home-link__ico {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    color: #494949;
}
.home-link__text {
    display: block;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    font-size: 1.125em;
    color: #494949;
}
.home-link:focus .home-link__ico,
.home-link:hover .home-link__ico {
    background: #361d83;
    color: #fff;
}
.open-photo {
    position: relative;
    max-width: 90%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 15px 27px 0 rgba(132, 146, 175, 0.3);
}
.open-photo__close {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background: #361d83;
    color: #fff;
    top: 10px;
    right: 10px;
    padding: 0;
    border: none;
    cursor: pointer;
}
.open-photo__btn {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    width: 50px;
    line-height: 48px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dbdcde;
    color: #a5a5a5;
    cursor: pointer;
}
.open-photo__btn:hover {
    color: #4b4b4b;
    transition: all 0.3s ease;
}
.open-photo__btn--type2 {
    left: unset;
    right: 15px;
}
.open-photo__item {
    text-align: center;
    display: flex;
}
.open-photo__item img {
    max-width: 100%;
    width: auto;
    max-height: calc(100vh - 60px);
    object-fit: contain;
}
.notification-block {
    position: relative;
    width: 350px;
    background: #6c55e2;
    padding: 15px;
    box-shadow: 0 0 20.37px 0.63px rgba(2, 6, 14, 0.1);
    border: 1px solid #5644b2;
    border-radius: 3px;
}
.close-notification {
    position: absolute;
    right: 5px;
    top: 5px;
    background: 0 0;
    border: none;
    cursor: pointer;
    color: #fff;
}
.notification {
    display: flex;
    align-items: center;
    font-size: 0.9375em;
    color: #fff;
}
.notification__inner {
    width: calc(100% - 30px);
}
.notification__text {
    margin-top: 5px;
    font-weight: 300;
}
.notification-window {
    position: fixed;
    top: 15px;
    right: 15px;
    width: auto;
    /* To make it display over Material UI's Dialog */
    z-index: 1400;
}
.notification-ico {
    width: 30px;
    color: #fff;
}
.slider-dot {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
.slider-dot__item {
    display: block;
    width: 15px;
    height: 15px;
    padding: 0;
    background: 0 0;
    background: rgba(17, 17, 17, 0.8);
    cursor: pointer;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 20px 0 rgba(255, 255, 255, 0.23);
}
.slider-dot__item + .slider-dot__item {
    margin-left: 6px;
}
.slider-dot__item.active {
    background: rgba(255, 255, 255, 0.9);
}
.preloader {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100px;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
.pay-modal,
.payment-popup,
.staff-comment-slider {
    position: relative;
}
.sharing-content {
    color: #2a2a2a;
    font-weight: 400;
    font-size: 0.9375em;
}
.sharing-photo {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.sharing-photo__item {
    width: 60px;
    margin-right: 10px;
    margin-top: 10px;
}
.pay-modal {
    width: 450px;
    background: #fff;
    padding: 30px 15px 15px;
}
.pay-block__title {
    font-weight: 800;
    font-size: 14px;
    color: #361d83;
}
.pay-block .checkout {
    padding: 15px;
    margin-top: 10px;
}
.StripeElement {
    margin-top: 15px;
}
.pay-block .checkout button {
    width: 120px;
    height: 40px;
    margin-top: 15px;
    background: #361d83;
    color: #fff;
    font-size: 0.9375em;
    border-radius: 3px;
    text-align: center;
    line-height: 100%;
    border: 1px solid transparent;
    cursor: pointer;
}
.work-slider__item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.verify-phone {
    margin-top: 5px;
}
.less-gutter-row {
    margin: 0 -5px;
}
.less-gutter-row > div {
    padding: 0 5px;
}
.nothing-text {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    text-align: center;
    font-size: 18px;
}
.tableWrap .nothing-text {
    background: #f5f5f5;
}
.staff-was-changed {
    font-size: 15px;
    color: #8e282c;
}
.staff-was-changed span {
    color: #857ae7;
}
.payment-popup {
    width: 450px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(132, 146, 175, 0.3);
}
.payment-popup__body {
    margin-top: 15px;
}
.payment-popup__header {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;
}
.payment-popup .form-item {
    border-width: 1px;
    border-radius: 3px;
}
.payment-text {
    color: #5b40a2;
}
.payment-value {
    text-align: right;
}
.payment-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.payment-logo__item {
    max-width: 60px;
}
.payment-logo__item + .payment-logo__item {
    margin-left: 10px;
}
.staff-public-profile .work-slider__item {
    height: 260px;
}
.staff-comment-slider .review-container {
    box-shadow: none;
}
.staff-comment-slider .comment-control__item {
    position: absolute;
    top: -44px;
}
.staff-comment-slider .comment-control__item--right {
    right: 0;
}
.staff-comment-slider .comment-control__item--left {
    right: 55px;
}
.main-container__content {
    margin-top: 30px;
    padding: 0 15px;
}
.dialog {
    display: flex;
    padding: 15px;
    background: #fff;
    border-bottom: 2px solid #ddd;
}
.dialog__info {
    width: calc(100% - 40px);
    padding-left: 20px;
    color: #707070;
}
.dialog__info h2 {
    font-weight: 700;
    font-size: 1.3125em;
}
.dialog__info p {
    font-size: 1.125em;
}
.dialog--unread {
    background: #ededed;
}
.dialog--new .dialog__info h2 {
    color: #361d83;
    font-weight: 900;
}
.dialog--active,
.dialog:focus {
    background: #7c65c2;
    color: #fff;
}
.dialog--active .dialog__info,
.dialog:focus .dialog__info {
    color: #fff;
}
.message-count {
    background: #fa491a;
    color: #fff;
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
}
.dialog-list {
    background: #ededed;
    width: 35%;
    height: 660px;
    overflow: auto;
    direction: rtl;
}
.dialog-list::-webkit-scrollbar {
    width: 6px;
}
.dialog-list::-webkit-scrollbar-thumb {
    background: #361d83;
    width: 6px;
    border-radius: 3px;
}
.dialog-list__inner {
    direction: ltr;
}
.message {
    margin-top: 15px;
}
.message-box {
    position: relative;
    display: flex;
    padding: 0 0 15px;
    background: #fff;
    box-shadow: 0 10px 51.33px 7.67px rgba(0, 0, 0, 0.02);
}
.message-content {
    width: 65%;
    background: #fff;
    border-left: 1px solid #ddd;
}
.message-content__inner {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 580px;
    padding-left: 15px;
    padding-right: 10px;
}
.message-inner-block {
    max-height: 560px;
    overflow: auto;
    padding-left: 10px;
    padding-right: 5px;
}
.message-inner-block::-webkit-scrollbar {
    width: 10px;
}
.message-inner-block::-webkit-scrollbar-thumb {
    background: #361d83;
    width: 10px;
    border-radius: 3px;
}
.message {
    display: flex;
    width: 100%;
    height: auto;
}
.message-author {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.message-author img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.message-text {
    position: relative;
    display: inline-flex;
    width: auto;
    max-width: calc(100% - 70px);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    margin-left: 30px;
    background: #361d83;
    padding: 10px;
    color: #fff;
    border-radius: 4px;
    font-size: 1.125em;
}
.message-text--owner {
    background: #fff;
    margin-left: 0;
    margin-right: 30px;
    color: #969696;
}
.message-head--mobile-only {
    display: none;
}
.dash-btn {
    display: block;
    line-height: 50px;
    width: 160px;
    height: 50px;
    border-radius: 5px;
    padding: 0 15px;
    background-color: #361d83;
    color: #fff;
    font-size: 0.9375em;
    letter-spacing: 0.05em;
    border: none;
    font-weight: 700;
}
.dash-btn:disabled {
    background-color: #b5b5b5 !important;
    cursor: not-allowed;
}
@media screen and (max-width: 1440px) {
    .dialog-list {
        width: 40%;
    }
    .message-content {
        width: 60%;
    }
}
@media screen and (max-width: 1200px) {
    .shop-card {
        margin: 10px;
        width: calc(50% - 20px); /* max 2 cards per row */
    }
    .sidebar-title h2 {
        font-size: 12px;
    }
    .settings__btn {
        font-size: 18px;
    }
    .nav-search__field {
        height: 36px;
        border-radius: 18px;
    }
    .shop-gallery__item {
        height: 40px;
        width: 40px;
        margin-right: calc((100% - (40px * 11)) / 10);
    }
    .work-slider__inner {
        padding: 0;
    }
}
@media screen and (max-width: 992px) {
    .onoffswitch {
        margin-left: 0;
    }
    .preloader {
        width: 60px !important;
        height: 60px !important;
    }
    .review-baner + .review-baner {
        margin-top: 10px;
    }
    .shop-group {
        margin-top: 15px;
    }
    .sidebar-title {
        padding: 5px 0;
    }
    .shop-schelude + .shop-schelude {
        margin-top: 5px;
    }
    .shop-group .theme-subtitle {
        padding-bottom: 0;
    }
    .sidebar-content {
        padding-bottom: 10px;
    }
    .grey-block {
        height: 15px;
    }
    .open-photo {
        width: calc(100% - 30px);
    }
    .open-photo__btn {
        width: 40px;
        height: 40px;
        line-height: 38px;
        left: 10px;
    }
    .open-photo__btn--type2 {
        left: unset;
        right: 10px;
    }
    .field-label {
        padding: 8px 0;
        font-size: 0.8125em;
    }
    .home-links {
        padding: 15px 0;
    }
    .home-link {
        width: 25%;
        padding: 0 5px;
    }
    .home-link__ico {
        width: 45px;
        height: 45px;
    }
    .home-link__text {
        font-size: 0.75em;
        font-weight: 400;
    }
    .section-title {
        padding: 15px 0;
    }
    .section-title > h2 {
        font-size: 2em;
    }
    .temp-ico {
        font-size: 1.5em;
    }
    .single-service-price {
        font-size: 0.875em;
    }
    .latest-img-item {
        width: 33.33%;
        margin-top: 5px;
        margin-bottom: 0;
    }
    .calendar-event > p,
    .calendar-event > span,
    .month {
        display: none;
    }
    .calendar-head {
        flex-direction: column;
        align-items: flex-start;
    }
    .calendar-event {
        padding: 0;
        position: static;
    }
    .calendar-wrap {
        position: relative;
        margin-top: 20px;
    }
    .event {
        width: 290px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 230px;
    }
    .calendar-event {
        display: block;
        margin: 5px;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background: #1b5bed;
        border: none;
    }
    .calendar-event--type2 {
        background: #8dc63f;
    }
    .number-td.active {
        border-bottom: 2px solid #1b5bed;
    }
    .head-title {
        font-size: 1.0625em;
    }
    .calendar-inner {
        min-height: 30px;
    }
    .message-head h2 {
        font-size: 1.3125em;
    }
    .book-window {
        width: calc(100% - 20px);
        margin: 0 auto;
        /* padding: 10px; */
    }
    .book-window .MuiDialogTitle-root {
        padding: 0 14px;
    }
    .book-window .MuiDialogContent-root {
        padding: 8px 0;
    }
    .news-item-container {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .theme-modal--album {
        width: 290px;
    }
    .main-container__content {
        padding: 0;
    }
    .message-inner-block {
        padding-left: 0;
        padding-right: 10px;
    }
    .message-head {
        margin-top: 10px;
        padding: 15px;
    }
    .message-head h2 {
        font-size: 1.3125em;
    }
    .message-content {
        width: 100%;
        height: auto;
    }
    .message-content__inner {
        height: auto;
        max-height: 600px;
        padding: 0 0 0 10px;
    }
    .dialog {
        padding: 10px;
    }
    .dialog-list {
        width: 100%;
        height: auto;
        max-height: 500px;
    }
    .dialog__info {
        padding-left: 10px;
    }
    .dialog__info h2 {
        font-size: 1.125em;
    }
    .dialog__info p {
        font-size: 1em;
    }
    .message-text--owner {
        margin-left: 3px;
        margin-right: 15px;
    }
    .message-text {
        margin-left: 15px;
        width: calc(100% - 40px);
        max-width: calc(100% - 40px);
        font-size: 1em;
    }
    .month-select__item {
        font-size: 2em;
    }
    .message-box {
        flex-wrap: wrap;
        padding-left: 0;
    }
    .month-select {
        width: 280px;
    }
    .month-select__box,
    .month-select__item {
        width: 220px;
    }
    .recomended {
        margin-top: 10px;
    }
    .latest-review-text {
        margin-top: 5px;
    }
}
@media screen and (max-width: 991px) {
    /* smaller headings' font size */
    .h1,
    h1 {
        font-size: 1.5rem;
    }
    .h2,
    h2 {
        font-size: 1.4rem;
    }
    .h3,
    h3 {
        font-size: 1.3rem;
    }
    .h4,
    h4 {
        font-size: 1.20rem;
    }
    .h5,
    h5 {
        font-size: 1.1rem;
    }
    .h6,
    h6 {
        font-size: 1rem;
    }

    .modal-inner-content {
        padding: 0 15px 15px;
    }
    .theme-modal__header {
        padding: 15px;
    }
    .modal-wrapper__inner .event {
        max-width: 290px;
        transform: none;
        left: 0;
        margin: 0;
    }
    .shop-card {
        margin: 10px;
        width: calc(50% - 20px); /* max 2 cards per row */
    }
    .open-photo__close {
        right: 0;
        top: -50px;
    }
    .shop-news {
        padding: 10px;
        border: 1px solid #ececec;
    }
    .shop-news-attachment-box {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .shop-news-attachment-thumb {
        margin-left: 15px;
        width: 32%;
        min-width: 100px;
    }
    .shop-review-header .more-link {
        margin-top: 0;
    }
    .shop-mob-review .review-content .more-link {
        text-align: right;
        color: #361d83;
    }
    .shop-review-header .review-info {
        font-size: 14px;
    }
    .shop-group .slick-slider {
        margin-top: 20px;
    }
    .shop-mob-gallery {
        position: relative;
    }
    .shop-mob-gallery__row {
        margin: 0 -5px;
    }
    .shop-gallery-slide {
        padding: 0 5px;
        height: 100px;
    }
    .shop-gallery-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .shop-mob-review {
        position: relative;
    }
    .shop-book-col .field-wrap .field-ico--to-right {
        display: flex;
        justify-content: center;
        width: auto;
    }
    .sidebar-title__link,
    .sidebar-title__link .sidebar-title__ico {
        color: #361d83;
    }
    .field-label--sm-no-transform {
        text-transform: none !important;
    }
    .sidebar-group {
        width: 100%;
    }
    .settings-title {
        margin: 15px 0;
    }
    .work-slider--interested .slider-control {
        left: 10px;
    }
    .work-slider--interested .slider-control--right {
        left: unset;
        right: 10px;
    }
    .slider-control {
        width: 40px;
        height: 40px;
    }
    .work-slider--interested {
        margin-top: 15px;
    }
    .modal-wrapper {
        margin-left: 0 !important;
    }
    .product-slider__main .slider-control {
        display: none;
    }
    .book-tab__item {
        padding: 3px 15px;
    }
    .book-tab__item:after {
        height: 26px;
    }
    .message-head--mobile-only {
        position: relative;
        display: flex;
        justify-content: flex-start;
        z-index: 2;
    }
    .message-head--mobile-only .message-author {
        margin: 0 15px;
    }
    .message-head--mobile-only .dash-btn {
        width: auto;
        height: auto;
        border: none;
        background: 0 0;
        padding: 0;
        line-height: 1;
    }
    .dash-btn {
        width: 130px;
        height: 40px;
        line-height: 40px;
        font-size: 0.8125em;
    }
}
@media screen and (max-width: 767px) {
    /* smaller headings' font size */
    .h1,
    h1 {
        font-size: 1.25rem;
    }
    .h2,
    h2 {
        font-size: 1.20rem;
    }
    .h3,
    h3 {
        font-size: 1.15rem;
    }
    .h4,
    h4 {
        font-size: 1.10rem;
    }
    .h5,
    h5 {
        font-size: 1.05rem;
    }
    .h6,
    h6 {
        font-size: 1rem;
    }

    /* For Material UI Dialog */
    .app-dialog-actions {
        padding: 15px !important;
    }

    .open-photo__item {
        margin: 0 auto;
    }
    .open-photo__item img {
        max-width: 100%;
    }
    .staff-public-profile .work-slider {
        width: 290px;
        margin: 30px auto 0;
    }
    .staff-public-profile .work-slider__item {
        height: 290px;
    }
    .open-photo__close {
        top: -50px;
        right: 0;
    }
    .payment-popup {
        width: calc(100% - 30px);
        padding: 15px 15px 30px;
    }
    .price-item {
        width: 100%;
    }
    .product-item__img {
        height: unset;
    }
    .gallery-col .gallery-item,
    .gallery-col .gallery-item > img,
    .product-item__img > img {
        height: auto;
    }
    .payment-logo {
        margin-top: 10px;
        justify-content: flex-start;
    }
    .payment-value {
        text-align: left;
    }
    .event-block .react-date-picker__calendar {
        max-width: 300px;
        margin-left: -27px;
    }
    .header > .container {
        padding: 0 5px;
    }
    .header > .container > .row {
        margin: 0 -5px;
    }
    .shop-book-col .react-date-picker__calendar {
        width: 290px;
    }
    .shop-book-col .react-date-picker__calendar--above-label {
        bottom: unset;
        top: calc(100% + 5px);
        left: unset;
        right: -12px;
    }
    .shop-book-col .date-drop {
        right: 0;
    }
    .shop-main__header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        flex-direction: row-reverse;
        z-index: 20;
    }
    .shop-main__header .shop-labels {
        font-size: 12px;
        justify-content: flex-start;
    }
    .slick-dots li button:focus:before,
    .slick-dots li button:hover:before {
        opacity: 0.25;
    }
    .slick-dots li.active button:hover:before,
    .slick-dots li.slick-active button:focus:before {
        opacity: 1;
    }
    .pay-modal {
        width: 290px;
    }
    .select-group p {
        font-size: 13px;
    }
    .cust-select-box {
        width: 180px;
    }
    .product-price {
        margin-top: 15px;
    }
    .product-price__new {
        font-size: 1.5625em;
    }
    .product-price__old {
        font-size: 1.0625em;
    }
    .shop-card {
        margin: 30px auto 0;
        width: calc(100% - 20px); /* max 1 card per row */
    }
    .notification-block {
        width: 290px;
        padding: 10px;
    }
    .recomended__img {
        width: 40px;
        height: 40px;
    }
    .shop-group {
        margin-top: 30px;
    }
    .confirm-code-btn {
        padding: 15px;
        flex-direction: column;
    }
    .section-title > h2 {
        font-size: 1.5625em;
        padding-right: 5px;
    }
    .slider-btn {
        left: 15px;
    }
    .slider-btn--right {
        left: unset;
        right: 15px;
    }
    .sidebar-group {
        padding: 0 10px;
    }
    .book-section {
        background: #fff;
    }
    .book-tab {
        width: 100%;
        padding: 15px 0;
    }
    .book-tab__item {
        padding: 5px 15px;
    }
    .select-group + .select-group {
        margin-left: 0;
    }
    .select-group,
    .table-select {
        flex-direction: column;
        align-items: flex-start;
    }
    .select-group p {
        padding: 10px 0;
    }
    .shop-gallery {
        flex-wrap: wrap;
    }
    .shop-gallery__item {
        margin-top: 10px;
        margin-right: 10px;
    }
    .theme-modal--album {
        width: 290px !important;
    }
    .news-prev-img {
        height: 100px;
    }
    .sharing-photo .sharing-photo__item .news-prev-img {
        height: 50px;
    }
    .sharing-photo .sharing-photo__item {
        margin-top: 0;
    }
    .review-container {
        padding: 30px 15px 0;
    }
    .theme-modal {
        width: 290px !important;
    }
    .theme-modal__header {
        padding: 10px;
    }
    .theme-modal__header h2 {
        font-size: 1.5em;
    }
    .theme-modal__body {
        padding: 5px 10px 15px;
    }
    .modal-inner-content {
        padding: 0 15px;
    }
}
