.button {
  display: inline-block;
  margin: 0 .25rem;
  padding: .25rem .75rem;
  min-width: 120px;
  border: 1px solid transparent;
  border-radius: .25rem;
  background-color: transparent;
  font-size: 1rem;
  line-height: 2;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  white-space: nowrap;
}

.button:focus,
.button:hover {
  transition: all 0.3s ease;
}

.button:disabled {
  cursor: not-allowed;
  opacity: .65;
  background-color: #DDD !important;
  border-color: #333 !important;
  color: #333 !important;
}



/* ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** */

/**
 * Shape :: round
 */
.button.shapeRound {
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  padding-top: 0;
  padding-bottom: 0;
}

.button.shapeRound.sizeSmall {
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
}

/* ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** */

/**
 * Size :: small
 */
.button.sizeSmall {
  min-width: auto;
}

/**
 * Size :: block
 */
.button.sizeBlock {
  display: block;
  margin: .2rem auto;
  min-width: auto;
  width: calc(100% - .5rem);
}

/* ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** */

/******************************
 * Theme :: Primary
 */

.button.themePrimary {
  border-color: #361d83;
  font-weight: 700;
}

 /* Outlined */
.button.themePrimary.variantOutlined {
  border-color: #AAA;
  color: #000;
}
.button.themePrimary.variantOutlined:not(:disabled):focus,
.button.themePrimary.variantOutlined:not(:disabled):hover {
  background-color: #361d83;
}

/* Filled */
.button.themePrimary.variantFilled {
  background-color: #361d83;
  color: #fff;
}

.button.themePrimary:not(:disabled):focus,
.button.themePrimary:not(:disabled):hover {
  background-color: #361d83; /* #6948d0; */
  color: #fff;
}


/******************************
 * Theme :: Secondary
 */

.button.themeSecondary {
  border-color: #1b5bed;
}

/* Outlined */
.button.themeSecondary.variantOutlined {
  color: #1b5bed;
}
.button.themeSecondary.variantOutlined:not(:disabled):focus,
.button.themeSecondary.variantOutlined:not(:disabled):hover {
  background-color: #1b5bed;
}

/* Filled */
.button.themeSecondary.variantFilled {
  background-color: #1b5bed;
  color: #fff;
}

.button.themeSecondary:not(:disabled):focus,
.button.themeSecondary:not(:disabled):hover {
  background-color: #3B6EF9;
  color: #fff;
}

/******************************
 * Theme :: Success
 */

.button.themeSuccess {
  border-color: #8dc63f;
  font-weight: 700;
}

/* Outlined */
.button.themeSuccess.variantOutlined {
  color: #8dc63f;
}
.button.themeSuccess.variantOutlined:not(:disabled):focus,
.button.themeSuccess.variantOutlined:not(:disabled):hover {
  background-color: #8dc63f;
}

/* Filled */
.button.themeSuccess.variantFilled {
  background-color: #8dc63f;
  color: #fff;
}

.button.themeSuccess:not(:disabled):focus,
.button.themeSuccess:not(:disabled):hover {
  background-color: #97CC49;
  color: #fff;
}

/******************************
 * Theme :: Danger
 */

.button.themeDanger {
  border-color: #dc3545;
}

/* Outlined */
.button.themeDanger.variantOutlined {
  color: #dc3545;
}
.button.themeDanger.variantOutlined:not(:disabled):focus,
.button.themeDanger.variantOutlined:not(:disabled):hover {
  background-color: #dc3545;
}

/* Filled */
.button.themeDanger.variantFilled {
  background-color: #dc3545;
  color: #fff;
}

.button.themeDanger:not(:disabled):focus,
.button.themeDanger:not(:disabled):hover {
  background-color: #EA414F;
  color: #fff;
}

/******************************
 * Theme :: Muted
 */

.button.themeMuted {
  border-color: #CCC;
}

/* Outlined */
.button.themeMuted.variantOutlined {
  color: #494949;
}

/* Filled */
.button.themeMuted.variantFilled {
  background-color: #FFF;
  color: #494949;
}

.button.themeMuted:not(:disabled):focus,
.button.themeMuted:not(:disabled):hover {
  background-color: #EEE;
  color: #494949;
}

/* ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** */

.button.elevated {
  margin: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2) !important;
}
