.root {
  margin: 10px 0;
  padding: 0 15px;
}

/* 5 cells per row */
.root {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.title {
  padding: 10px 0;
  font-weight: 900;
  color: #666;
  text-transform: uppercase;
}

.coverAnchor,
.coverAnchor:focus,
.coverAnchor:hover {
  color: #f3f3f3;
}

.coverAnchor i {
  font-size: 1.5em;
}

@media screen and (max-width: 1200px) {
  /* 3 cells per row */
  .root {
    flex: 0 0 33.3% !important;
    max-width: 33.3% !important;
  }
}

@media screen and (max-width: 767px) {
  /* 1 cell per row */
  .root {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .title {
    text-align: center;
  }
}
