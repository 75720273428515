.canvasBox {
  position: relative;
}
.canvasBox canvas {
  display: block;
  margin: auto;
}
.canvasBox .uploadOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
}
@media screen and (max-width: 1199px) {
  .canvasBox canvas {
    height: 40vh !important;
  }
}
@media screen and (max-width: 767px) {
  .canvasBox canvas {
    width: 290px !important;
    height: 290px !important;
  }
}
