/* @import url("https://fonts.googleapis.com/css?family=Noto+Serif+TC&display=swap"); */

@media only screen and (max-width: 991px) {
	.shop-search-custom {
		background-color: #852B7D!important;
		color: white!important;
		cursor: pointer!important;
		box-shadow: 5px 5px 10px rgba(0,0,0,0.2)!important;
		padding: 5px!important;
		border-radius: 2px!important;
		height: 25px!important;
		margin-bottom: 10px!important;
		margin-top: 5px!important;
		border-radius: 2px!important;
		height: 50px!important;
	}
}

.main-icon {
	height:25px!important;
	width:25px!important;
	text-align:center!important;
	-webkit-filter:invert(0);
}
.main-icon-parent:hover .main-icon {
	-webkit-filter: invert(1);
}

/* These class name is adopted by rich text editor: */
.video-container-s {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.video-container-s iframe,
.video-container-s object,
.video-container-s embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
}

.review-baner {
	width: auto!important;
	height: auto!important;
}
