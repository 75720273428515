.card {
  position: relative;
  margin-top: 60px;
  margin-bottom: 10px;
  box-shadow: 0 8px 62px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  height: calc(100% - 60px);
}
.card a {
  color: #404040;
}
.customerAvatar {
  position: absolute;
  right: 0;
  top: -50px;
  width: 100px;
}
.review {
  font-size: 1em;
  font-weight: 300;
}
.shopName {
  font-weight: 600;
  color: #361d83;
  font-size: 1.125em;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footRow {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 60%;
}
.footCell {
  width: 62px;
}

@media screen and (max-width: 1199px) {
  .footer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .footRow {
    width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 992px) {
  .review {
    font-size: 0.875em;
  }
  .customerAvatar {
    width: 90px;
    top: -45px;
  }
}
