.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #39b54a;
}
.dot.off {
  background: #494949;
}
