.root {
    background-color: #fafafa;
}

/* 5 cells per row */
.cellContainer {
    flex: 0 0 20% !important;
    max-width: 20% !important;
}

@media screen and (max-width: 1200px) {
    /* 3 cells per row */
    .cellContainer {
        flex: 0 0 33.3% !important;
        max-width: 33.3% !important;
    }
}

@media screen and (max-width: 767px) {
    /* 1 cell per row */
    .cellContainer {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
